<template>
  <section>
    <div
      ref="formBuilder"
      v-if="
        !hideActions &&
        currentEntity &&
        currentEntity.templates &&
        currentEntity.templates.length == 1 &&
        (customization == null ||
          (customization &&
            customization.tab_groups &&
            customization.tab_groups.length == 0)) &&
        currentEntity.entity_type == 'GENERAL'
      "
      v-loading="loading"
      :element-loading-text="loadingText"
      :style="getContainerStyle('FULLSCREEN')"
    >
      <!-- <el-scrollbar
        :wrap-style="getContainerStyle('FULLSCREEN')"
        v-if="showData"
        class="scrollable-element"
      > -->
      <div class="template-body-section">
        <el-form @submit.native.prevent v-if="interactiveQuestionsMode">
          <preview-interactive-template-form
            :fieldsData="getFieldsData"
            :isExecute="isExecuteCopy"
            :form="form"
            :interactiveQuestionsModeType="interactiveQuestionsModeType"
            :dataId="entityDataId"
            :isFormFormbuilder="false"
            @onFilledQuestion="updateInteractiveQuestionFillingStatus"
          ></preview-interactive-template-form>
        </el-form>

        <el-form v-else @submit.native.prevent>
          <div class="form-builder" :style="getPopUpStyle('FULLSCREEN')">
            <div
              v-if="fieldsData && fieldsData.length && !hasRepeatableTemplate"
              class="form-fields-holder"
            >
              <div
                v-for="(field, index) of getFieldsData"
                :key="index"
                :style="
                  isEmailView
                    ? {
                        ...getExistedStyle(field),
                        marginLeft: '30px',
                        marginTop: '8px',
                      }
                    : getIsMobile
                    ? field.input_type === 'NAV_BAR'
                      ? getStyle(field)
                      : ''
                    : getExistedStyle(field) || getStyle(field, index)
                "
              >
                <component
                  v-bind:is="
                    field && field.components && field.components.execute
                      ? field.components.execute
                      : fieldMap[field.input_type]
                  "
                  :rules_hide="!checkhideFields(field)"
                  :data="field"
                  :form="form"
                  :is-view="!isExecuteCopy"
                  :label="getFieldLabelFromRules(field)"
                  :workflowDataId="entityDataId"
                  :fieldsData="fieldsData"
                  :entityDataId="entityDataId"
                  :entityId="
                    field.input_type == 'QR' ? currentEntity._id : getEntityId
                  "
                  :entityData="entityData"
                  :selectedTableRows="allSelectedDataTableRows"
                  :entity_type="currentEntity.entity_type"
                  :selfTemplateId="selfTemplateId"
                  :templateName="templateName"
                  :allEntitiesData="allEntitiesData"
                  :allFieldsData="allFieldsData"
                  :hasLabel="hasLabel"
                  :colorFields="colorFields"
                  :checkIsDisabled="checkIsDisabled(field)"
                  :filesInfo="filesInfo"
                  :templatesData="existedTemplateDataValues"
                  :index="index"
                  :key="componentKey"
                  :class="
                    [
                      'MULTI_LINE_TEXT',
                      'RICH_TEXT',
                      'ENTITY_VARIABLE',
                      'ENTITY_TABLE',
                      'DATA_TABLE',
                      'NAV_BAR',
                      'CURRENCY_TYPE',
                      'DIVISION',
                    ].includes(field.input_type)
                      ? 'dataTable' + index
                      : ''
                  "
                  :ref="
                    [
                      'ENTITY_TABLE',
                      'DATA_TABLE',
                      'NAV_BAR',
                      'CURRENCY_TYPE',
                      'DIVISION',
                    ].includes(field.input_type)
                      ? 'datatable'
                      : ''
                  "
                  :hideOptions="hideOptions"
                  :primaryData="getNewEntityData"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                  :allFormTemplates="allFormTemplates"
                  :entitiesToRefresh="entitiesToRefresh"
                  :formbuilderDetails="formbuilderDetails"
                  :loadingButtonFields="loadingFields"
                  :suggestedData="suggestedData"
                  :autoFillEntityData="getAutoFillEntityData(field)"
                  :isApplicationUserSide="isApplicationUserSide"
                  :fromAddWidget="fromAddWidget"
                  :refreshEntityFields="refreshEntityFields"
                  :entityDataExists="entityDataExists"
                  :isFromFormBuilder="isFromFormBuilder"
                  :isFromChildTable="false"
                  :visibility="checkhideFields(field)"
                  :savingData="savingData"
                  :hiddenColumns="hideFields"
                  :checkAllFieldsDisabled="checkIsDisabled(field)"
                  :disabledColumnsFields="disabledFields"
                  :currentEntitySettings="entitySettings"
                  :currentEntity="currentEntity"
                  :currentStep="currentStep"
                  :type="routeType"
                  :category="category"
                  :isApprovalView="isUserApprovingForm"
                  :childTableApprovalPermissions="childTableApprovalPermissions"
                  :entityTableDetails="getEntityTableDetails(field, form)"
                  :integration-data="integrationData"
                  :integrationFieldValue="integrationFieldValue"
                  :triggeredIntegrationField="dependentFields"
                  :filteredIntegrationVariableFields="
                    allFilteredIntegrationVaribleFields
                  "
                  :selectedIntegrationRecord="selectedIntegrationRecord"
                  :fromScheduling="fromScheduling"
                  @setDefultValuesForIndividualFields="setDefultValuesForFields"
                  @applyFormRules="rulesEventEmitter"
                  @saveTemplateFromActionButton="saveTemplateFromActionButton"
                  @actionButtonCallBacks="actionButtonCallBacks"
                  @loadComponent="loadCurrentComponent"
                  @fileUploadData="handleFileUploadData"
                  @applyDefaultValues="setDefultValuesForFields"
                  @entityDataUpdated="setDataToEntityVariables"
                  @clearEntityFields="unsetEntityVariables"
                  @updateTableData="updateTableFieldData"
                  @onNewRowAdded="updateTopForBelowElements"
                  @onClickActionTriggered="handleOnClickAction"
                  @updateHiddenColumns="updateHiddenColumns"
                  @onDataAdded="rulesEventEmitter"
                  @integrationDataUpdated="setDataToIntegrationVariables"
                  @integrationFieldValueUpdated="setDataToIntegrationField"
                  @handleFormChange="handleFormChange"
                  @schedule-event-called="SaveScheduleEventData"
                >
                </component>
              </div>
              <TryUsingAI
                v-if="showTryUsingAI"
                :form="form"
                :fieldsData="fieldsData"
                type="EntityData"
                @close="handleCloseTryUsingAI"
                @copy-content="handleCopyContent"
              >
              </TryUsingAI>
            </div>

            <template-fields-repeatable
              v-if="hasRepeatableTemplate"
              :templateData="templateData"
              :entityRepeatableTemplateData="entityRepeatableTemplateData"
              :form="form"
              :entitiesData="entitiesData"
              :isSubmit="false"
              :isView="!isExecuteCopy"
              :getDefaultDateFormat="getDefaultDateFormat"
              :singleCompanyTemplateCheckBoxArray="
                singleCompanyTemplateCheckBoxArray
              "
              :entityDataId="entityDataId"
              :max_value="max_value"
              :min_value="min_value"
              :templateRules="templateRules"
              :templateRuleFields="templateRuleFields"
              :hideLabel="true"
            >
            </template-fields-repeatable>
          </div>
        </el-form>
      </div>
      <!-- </el-scrollbar> -->
      <div v-if="fromScheduling" class="bottom-bar">
        <div class="mr-3">
          <el-button
            v-if="isEdit"
            type="primary"
            @click="saveTemplate(false)"
            class="scaling-button"
            :size="buttonSize"
            :style="buttonStyles"
          >
            {{ getSaveButtonLabel() }}
          </el-button>
          <el-button
            v-else-if="!isApplicationUserSide"
            type="primary"
            @click="goEditMode"
            :style="getBrandingStyles"
          >
            {{
              customization &&
              customization.buttons &&
              customization.buttons.length
                ? customization.buttons[3].value
                : "Edit"
            }}
          </el-button>
        </div>
      </div>
    </div>
    <div
      v-else
      :class="{
        'preview-template-view': !isFormbuilderParent,
        'formbuilder-as-a-parent': isFormbuilderParent,
      }"
      v-loading="loading"
      :element-loading-text="loadingText"
    >
      <el-row
        v-if="
          !formbuilderDetails ||
          !formbuilderDetails.customization ||
          !formbuilderDetails.customization.button_position ||
          (formbuilderDetails &&
            formbuilderDetails.customization &&
            formbuilderDetails.customization.button_position == 'TOP')
        "
        style="border-bottom: 1px solid #e6e6e6; display: flex"
        :class="rowAlignment"
      >
        <div
          style="margin-right: 20px; margin-bottom: 10px; margin-top: 10px"
          v-if="
            (checkEditPermission() || checkAddPermission()) &&
            !fromAddWidget &&
            !fromPopup &&
            !checkFormbuilderStatus('APPROVED') &&
            checkIsDeletedData
          "
        >
          <el-button
            v-if="isEdit"
            type="primary"
            @click="saveTemplate(false)"
            class="scaling-button"
            :size="buttonSize"
            :style="buttonStyles"
            :loading="isButtonLoading"
          >
            {{ getSaveButtonLabel() }}
          </el-button>
          <div v-else>
            <el-link
              v-if="
                hasEditAccess &&
                !isFromRelationTab &&
                IframePermissions('EDIT', 'EntityDetailedView') &&
                IframePermissions('EDIT', 'ViewEntityData') &&
                IframePermissions('EDIT', 'EntityGalleryView') &&
                checkRolePermission('editEntitiesData')
              "
              icon="el-icon-edit"
              type="button"
              :underline="false"
              @click="goToEditMode"
              class="el-button el-button--primary el-button--mini scaling-button"
              :style="getBrandingStyles"
            >
              {{
                customization &&
                customization.buttons &&
                customization.buttons.length
                  ? customization.buttons[3].value
                  : "Edit"
              }}</el-link
            >
          </div>
          <el-button
            v-if="
              isEdit &&
              !currentStep.has_next &&
              entityHasSingleTemplate &&
              entityDataId &&
              (!currentEntity || currentEntity.entity_type != 'INDIVIDUAL') &&
              checkPerimission('DUPLICATE') &&
              checkButtonVisible('Duplicate')
            "
            size="mini"
            type="primary"
            @click="cloneEntityData"
            class="scaling-button"
            >{{
              customization &&
              customization.buttons &&
              customization.buttons.length
                ? customization.buttons[5].value
                : "Duplicate"
            }}
          </el-button>
          <el-button
            v-if="
              isEdit &&
              !currentStep.has_next &&
              !entityDataId &&
              checkButtonVisible('Save & New')
            "
            size="mini"
            type="primary"
            @click="saveTemplate(true)"
            class="scaling-button"
            >{{
              customization &&
              customization.buttons &&
              customization.buttons.length
                ? customization.buttons[2].value
                : "Save & New"
            }}
          </el-button>
        </div>
        <div
          style="margin-right: 20px; margin-bottom: 10px"
          v-if="
            !isUserApprovingForm &&
            (checkFormbuilderStatus('APPROVED') ||
              checkFormbuilderStatus('REJECTED'))
          "
        >
          <div v-if="checkFormbuilderStatus('APPROVED')">
            <h4 class="success">Approved</h4>
          </div>
          <div v-else>
            <el-popover
              placement="top-start"
              title="Rejection details"
              width="400"
              trigger="hover"
            >
              <div>
                <span v-if="getRejectedUser && getRejectedUser.rejected_by"
                  >Rejected by: {{ getRejectedUser.rejected_by }}
                </span>
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_at"
                  >At {{ getRejectedUser.rejected_at | globalDateFormat }}</span
                >
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_reason">
                  Reason:
                  {{ getRejectedUser.rejected_reason }}
                </span>
              </div>
              <h4 class="error" slot="reference">Rejected</h4>
            </el-popover>
          </div>
        </div>
        <div
          style="margin-right: 20px; margin-bottom: 10px"
          v-else-if="isUserApprovingForm"
        >
          <div v-if="checkFormbuilderStatus('APPROVED')">
            <el-popover
              placement="top-start"
              title="Approval details:"
              width="400"
              trigger="hover"
            >
              <div v-if="currentFormbuilderData.priority_based">
                <span v-if="getApprovedUser && getApprovedUser.approved_by"
                  >Approved by: {{ getApprovedUser.approved_by }}
                </span>
                <br />
                <span v-if="getApprovedUser && getApprovedUser.approved_at"
                  >Approved at:
                  {{
                    convertToLocalDateTime(getApprovedUser.approved_at)
                      | globalDateFormat
                  }}</span
                >
              </div>
              <div v-else>
                <ul>
                  <li
                    v-for="approver in getApprovedUser"
                    :key="approver._id"
                    class="list-style"
                  >
                    <div v-if="approver.approved_by && approver.approved_at">
                      <i class="el-icon-user-solid icon-style"></i>
                      Approved by:<span class="approver-text">{{
                        " " + approver.approved_by + ","
                      }}</span
                      ><br />
                      <i class="el-icon-time icon-style"></i>
                      Approved at:<span class="approver-text">{{
                        (" " + convertToLocalDateTime(approver.approved_at))
                          | globalDateTimeFormat
                      }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <h4 class="success" slot="reference">
                Approved <i class="el-icon-info"></i>
              </h4>
            </el-popover>
          </div>
          <div v-else-if="checkFormbuilderStatus('REJECTED')">
            <el-popover
              placement="top-start"
              title="Rejection details"
              width="300"
              trigger="hover"
            >
              <div>
                <span v-if="getRejectedUser && getRejectedUser.rejected_by"
                  >Rejected by: {{ getRejectedUser.rejected_by }}
                </span>
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_at"
                  >Rejected at
                  {{
                    convertToLocalDateTime(getRejectedUser.rejected_at)
                      | globalDateFormat
                  }}</span
                >
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_reason">
                  Reason:
                  {{ getRejectedUser.rejected_reason }}
                </span>
              </div>
              <h4 class="error" slot="reference">
                Rejected <i class="el-icon-info"></i>
              </h4>
            </el-popover>
          </div>
          <div v-else class="d-flex">
            <div v-if="getCurrentUser && getCurrentUser.approved_status">
              <h4 class="success">You have already approved</h4>
            </div>
            <div v-else-if="getCurrentUser && getCurrentUser.rejected_status">
              <h4 class="error">You have already rejected</h4>
            </div>
            <div v-else class="d-flex">
              <el-button
                @click="onApprove"
                size="mini"
                style="background-color: green; color: white"
                class="scaling-button"
                icon="el-icon-check"
                >Approve
              </el-button>
              <el-button
                @click="showRejectConfirm"
                size="mini"
                style="background-color: red; color: white"
                class="scaling-button"
                icon="el-icon-close"
                >Reject
              </el-button>
              <!-- v-if="getCurrentUser && (!getCurrentUser.approved_status || !getCurrentUser.rejected_status) -->
              <div
                v-if="
                  getReviewerData(currentFormbuilderData) &&
                  checkReviewer(currentFormbuilderData)
                "
              >
                <el-popover
                  placement="top-start"
                  :title="getReviewerData(currentFormbuilderData).title"
                  width="300"
                  trigger="hover"
                >
                  <div>
                    <span v-if="getReviewerData(currentFormbuilderData).label1"
                      >{{ getReviewerData(currentFormbuilderData).label1 }}
                    </span>
                    <br />
                    <span v-if="getReviewerData(currentFormbuilderData).date"
                      >On:
                      {{
                        getReviewerData(currentFormbuilderData).date
                          | globalDateTimeFormat
                      }}</span
                    >
                    <br />
                    <span
                      v-if="getReviewerData(currentFormbuilderData).reason"
                      style="word-break: break-word"
                    >
                      Reason:
                      {{ getReviewerData(currentFormbuilderData).reason }}
                    </span>
                  </div>
                  <div slot="reference">
                    <i class="el-icon-info"></i>
                  </div>
                </el-popover>
              </div>
            </div>
            <formbuilderLogs
              class="primary"
              :currentFormbuilderData="currentFormbuilderData"
              :allEntitiesData="allEntitiesData"
              :allCompanyUserTypesData="allCompanyUserTypesData"
              v-if="!logsLoading"
            >
            </formbuilderLogs>
          </div>
        </div>
        <div
          v-else-if="!checkAddPermission() && !checkEditPermission()"
          style="margin-right: 20px; margin-bottom: 10px"
        >
          <el-button @click="changeTemplate" size="mini" type="primary">
            <span>Next</span>
          </el-button>
        </div>
        <span
          v-if="isEdit && this.templateData?.templateTryUsingAI"
          class="ai-icon float-right mr-2 ml-1"
        >
          <div class="ai-icon-content" @click="showTryUsingAIMethod">
            <icons :iconName="'TryUsingAI'"></icons>
            <span class="ai-text">Try Using AI</span>
          </div>
        </span>
      </el-row>

      <!-- {{ form }} -->
      <div :style="getContainerStyle" v-if="showData">
        <div class="template-body-section" :style="getSplitViewStyle">
          <el-form @submit.native.prevent v-if="interactiveQuestionsMode">
            <preview-interactive-template-form
              :fieldsData="getFieldsData"
              :isExecute="isExecuteCopy"
              :form="form"
              :interactiveQuestionsModeType="interactiveQuestionsModeType"
              :dataId="entityDataId"
              :isFormFormbuilder="false"
              @onFilledQuestion="updateInteractiveQuestionFillingStatus"
            ></preview-interactive-template-form>
          </el-form>

          <el-form v-else @submit.native.prevent>
            <div
              class="form-builder"
              ref="formBuilder"
              :style="isVerticalView ? { minHeight: '200px' } : getStylediv"
            >
              <div
                v-if="
                  fieldsData &&
                  fieldsData.length &&
                  !hasRepeatableTemplate &&
                  showData
                "
                :class="isVerticalView ? '' : 'form-fields-holder'"
                :style="
                  isVerticalView
                    ? {
                        display: 'grid',
                        gridTemplateColumns:
                          'repeat(auto-fit, minmax(400px, 1fr))',
                        marginLeft: '2px',
                        marginRight: '2px',
                      }
                    : getElementStyle
                "
              >
                <div
                  v-for="(field, index) of getFieldsData"
                  :key="index"
                  :style="computedStyle(field, index)"
                >
                  <component
                    v-bind:is="
                      field && field.components && field.components.execute
                        ? field.components.execute
                        : fieldMap[field.input_type]
                    "
                    :rules_hide="!checkhideFields(field)"
                    v-if="!checkhideFields(field)"
                    :class="
                      [
                        'MULTI_LINE_TEXT',
                        'RICH_TEXT',
                        'ENTITY_VARIABLE',
                        'ENTITY_TABLE',
                        'DATA_TABLE',
                        'NAV_BAR',
                        'CURRENCY_TYPE',
                        'DIVISION',
                      ].includes(field.input_type)
                        ? 'dataTable' + index
                        : ''
                    "
                    :ref="
                      [
                        'ENTITY_TABLE',
                        'DATA_TABLE',
                        'NAV_BAR',
                        'CURRENCY_TYPE',
                        'DIVISION',
                      ].includes(field.input_type)
                        ? 'datatable'
                        : ''
                    "
                    :data="field"
                    :form="form"
                    :is-view="!isExecuteCopy"
                    :label="getFieldLabelFromRules(field)"
                    :workflowDataId="entityDataId"
                    :fieldsData="fieldsData"
                    :entityDataId="entityDataId"
                    :entityId="
                      field.input_type == 'QR' ? currentEntity._id : getEntityId
                    "
                    :entityData="entityData"
                    :selectedTableRows="allSelectedDataTableRows"
                    :entity_type="currentEntity.entity_type"
                    :selfTemplateId="selfTemplateId"
                    :templateName="templateName"
                    :allEntitiesData="allEntitiesData"
                    :allFieldsData="allFieldsData"
                    :hasLabel="hasLabel"
                    :colorFields="colorFields"
                    :checkIsDisabled="checkIsDisabled(field)"
                    :filesInfo="filesInfo"
                    :templatesData="existedTemplateDataValues"
                    :index="index"
                    :key="componentKey"
                    :hideOptions="hideOptions"
                    :primaryData="getNewEntityData"
                    :singleCompanyTemplateCheckBoxArray="
                      singleCompanyTemplateCheckBoxArray
                    "
                    :allFormTemplates="allFormTemplates"
                    :entitiesToRefresh="entitiesToRefresh"
                    :formbuilderDetails="formbuilderDetails"
                    :loadingButtonFields="loadingFields"
                    :suggestedData="suggestedData"
                    :autoFillEntityData="getAutoFillEntityData(field)"
                    :isApplicationUserSide="isApplicationUserSide"
                    :fromAddWidget="fromAddWidget"
                    :refreshEntityFields="refreshEntityFields"
                    :entityDataExists="entityDataExists"
                    :isFromFormBuilder="isFromFormBuilder"
                    :isFromChildTable="false"
                    :visibility="checkhideFields(field)"
                    :savingData="savingData"
                    :hiddenColumns="hideFields"
                    :checkAllFieldsDisabled="checkIsDisabled(field)"
                    :disabledColumnsFields="disabledFields"
                    :currentEntitySettings="entitySettings"
                    :currentEntity="currentEntity"
                    :currentStep="currentStep"
                    :type="routeType"
                    :category="category"
                    :isApprovalView="isUserApprovingForm"
                    :childTableApprovalPermissions="
                      childTableApprovalPermissions
                    "
                    :entityTableDetails="getEntityTableDetails(field, form)"
                    :integration-data="integrationData"
                    :integrationFieldValue="integrationFieldValue"
                    :triggeredIntegrationField="dependentFields"
                    :filteredIntegrationVariableFields="
                      allFilteredIntegrationVaribleFields
                    "
                    :selectedIntegrationRecord="selectedIntegrationRecord"
                    :fromScheduling="fromScheduling"
                    @setDefultValuesForIndividualFields="
                      setDefultValuesForFields
                    "
                    @applyFormRules="rulesEventEmitter"
                    @saveTemplateFromActionButton="saveTemplateFromActionButton"
                    @actionButtonCallBacks="actionButtonCallBacks"
                    @loadComponent="loadCurrentComponent"
                    @fileUploadData="handleFileUploadData"
                    @applyDefaultValues="setDefultValuesForFields"
                    @entityDataUpdated="setDataToEntityVariables"
                    @clearEntityFields="unsetEntityVariables"
                    @updateTableData="updateTableFieldData"
                    @onNewRowAdded="updateTopForBelowElements"
                    @onClickActionTriggered="handleOnClickAction"
                    @updateHiddenColumns="updateHiddenColumns"
                    @onDataAdded="rulesEventEmitter"
                    @integrationDataUpdated="setDataToIntegrationVariables"
                    @integrationFieldValueUpdated="setDataToIntegrationField"
                    @handleFormChange="handleFormChange"
                    @schedule-event-called="SaveScheduleEventData"
                  >
                  </component>
                </div>

                <TryUsingAI
                  v-if="showTryUsingAI"
                  :form="form"
                  :fieldsData="fieldsData"
                  @close="handleCloseTryUsingAI"
                  @copy-content="handleCopyContent"
                >
                </TryUsingAI>
              </div>
              <div v-else>
                <template-fields-repeatable
                  v-if="hasRepeatableTemplate"
                  :templateData="templateData"
                  :entityRepeatableTemplateData="entityRepeatableTemplateData"
                  :form="form"
                  :entitiesData="entitiesData"
                  :isSubmit="false"
                  :isView="!isExecuteCopy"
                  :getDefaultDateFormat="getDefaultDateFormat"
                  :singleCompanyTemplateCheckBoxArray="
                    singleCompanyTemplateCheckBoxArray
                  "
                  :entityDataId="entityDataId"
                  :max_value="max_value"
                  :min_value="min_value"
                  :templateRules="templateRules"
                  :templateRuleFields="templateRuleFields"
                  :hideLabel="true"
                >
                </template-fields-repeatable>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <el-row
        v-if="
          formbuilderDetails &&
          formbuilderDetails.customization &&
          formbuilderDetails.customization.button_position == 'BOTTOM'
        "
        style="
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          height: 5vh;
          align-items: center;
        "
        :class="rowAlignment"
      >
        <div
          v-if="
            (checkEditPermission() || checkAddPermission()) &&
            !fromAddWidget &&
            !fromPopup &&
            !checkFormbuilderStatus('APPROVED') &&
            checkIsDeletedData
          "
        >
          <el-button
            v-if="isEdit"
            type="primary"
            @click="saveTemplate(false)"
            class="scaling-button"
            :size="getIsMobile ? 'mini' : buttonSize"
            :style="buttonStyles"
          >
            {{ getSaveButtonLabel() }}
          </el-button>
          <div v-else>
            <el-link
              v-if="
                hasEditAccess &&
                IframePermissions('EDIT', 'EntityDetailedView') &&
                IframePermissions('EDIT', 'ViewEntityData') &&
                IframePermissions('EDIT', 'EntityGalleryView') &&
                checkRolePermission('editEntitiesData')
              "
              icon="el-icon-edit"
              type="button"
              :underline="false"
              @click="goToEditMode"
              class="el-button el-button--primary el-button--mini scaling-button"
              :style="getBrandingStyles"
            >
              {{
                customization &&
                customization.buttons &&
                customization.buttons.length
                  ? customization.buttons[3].value
                  : "Edit"
              }}</el-link
            >
          </div>
          <el-button
            v-if="
              isEdit &&
              !currentStep.has_next &&
              entityHasSingleTemplate &&
              entityDataId &&
              (!currentEntity || currentEntity.entity_type != 'INDIVIDUAL') &&
              checkPerimission('DUPLICATE') &&
              checkButtonVisible('Duplicate')
            "
            size="mini"
            type="primary"
            @click="cloneEntityData"
            class="scaling-button"
            >{{
              customization &&
              customization.buttons &&
              customization.buttons.length
                ? customization.buttons[5].value
                : "Duplicate"
            }}
          </el-button>
          <el-button
            v-if="
              isEdit &&
              !currentStep.has_next &&
              !entityDataId &&
              checkButtonVisible('Save & New')
            "
            size="mini"
            type="primary"
            @click="saveTemplate(true)"
            class="scaling-button"
            >{{
              customization &&
              customization.buttons &&
              customization.buttons.length
                ? customization.buttons[2].value
                : "Save & New"
            }}
          </el-button>
        </div>
        <div
          style="margin-right: 20px; margin-bottom: 10px"
          v-if="
            !isUserApprovingForm &&
            (checkFormbuilderStatus('APPROVED') ||
              checkFormbuilderStatus('REJECTED'))
          "
        >
          <div v-if="checkFormbuilderStatus('APPROVED')">
            <h4 class="success">Approved</h4>
          </div>
          <div v-else>
            <el-popover
              placement="top-start"
              title="Rejection details"
              width="400"
              trigger="hover"
            >
              <div>
                <span v-if="getRejectedUser && getRejectedUser.rejected_by"
                  >Rejected by: {{ getRejectedUser.rejected_by }}
                </span>
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_at"
                  >At {{ getRejectedUser.rejected_at | globalDateFormat }}</span
                >
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_reason">
                  Reason:
                  {{ getRejectedUser.rejected_reason }}
                </span>
              </div>
              <h4 class="error" slot="reference">Rejected</h4>
            </el-popover>
          </div>
        </div>
        <div
          style="margin-right: 20px; margin-bottom: 10px"
          v-else-if="isUserApprovingForm"
        >
          <div v-if="checkFormbuilderStatus('APPROVED')">
            <el-popover
              placement="top-start"
              title="Approval details:"
              width="400"
              trigger="hover"
            >
              <div v-if="currentFormbuilderData.priority_based">
                <span v-if="getApprovedUser && getApprovedUser.approved_by"
                  >Approved by: {{ getApprovedUser.approved_by }}
                </span>
                <br />
                <span v-if="getApprovedUser && getApprovedUser.approved_at"
                  >Approved at:
                  {{
                    convertToLocalDateTime(getApprovedUser.approved_at)
                      | globalDateFormat
                  }}</span
                >
              </div>
              <div v-else>
                <ul>
                  <li
                    v-for="approver in getApprovedUser"
                    :key="approver._id"
                    class="list-style"
                  >
                    <div v-if="approver.approved_by && approver.approved_at">
                      <i class="el-icon-user-solid icon-style"></i>
                      Approved by:<span class="approver-text">{{
                        " " + approver.approved_by + ","
                      }}</span
                      ><br />
                      <i class="el-icon-time icon-style"></i>
                      Approved at:<span class="approver-text">{{
                        (" " + convertToLocalDateTime(approver.approved_at))
                          | globalDateTimeFormat
                      }}</span>
                    </div>
                  </li>
                </ul>
              </div>
              <h4 class="success" slot="reference">
                Approved <i class="el-icon-info"></i>
              </h4>
            </el-popover>
          </div>
          <div v-else-if="checkFormbuilderStatus('REJECTED')">
            <el-popover
              placement="top-start"
              title="Rejection details"
              width="300"
              trigger="hover"
            >
              <div>
                <span v-if="getRejectedUser && getRejectedUser.rejected_by"
                  >Rejected by: {{ getRejectedUser.rejected_by }}
                </span>
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_at"
                  >Rejected at
                  {{
                    convertToLocalDateTime(getRejectedUser.rejected_at)
                      | globalDateFormat
                  }}</span
                >
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_reason">
                  Reason:
                  {{ getRejectedUser.rejected_reason }}
                </span>
              </div>
              <h4 class="error" slot="reference">
                Rejected <i class="el-icon-info"></i>
              </h4>
            </el-popover>
          </div>
          <div v-else class="d-flex">
            <div v-if="getCurrentUser && getCurrentUser.approved_status">
              <h4 class="success">You have already approved</h4>
            </div>
            <div v-else-if="getCurrentUser && getCurrentUser.rejected_status">
              <h4 class="error">You have already rejected</h4>
            </div>
            <div v-else class="d-flex">
              <el-button
                @click="onApprove"
                size="mini"
                style="background-color: green; color: white"
                class="scaling-button"
                icon="el-icon-check"
                >Approve
              </el-button>
              <el-button
                @click="showRejectConfirm"
                size="mini"
                style="background-color: red; color: white"
                class="scaling-button"
                icon="el-icon-close"
                >Reject
              </el-button>
              <!-- v-if="getCurrentUser && (!getCurrentUser.approved_status || !getCurrentUser.rejected_status) -->
              <div
                v-if="
                  getReviewerData(currentFormbuilderData) &&
                  checkReviewer(currentFormbuilderData)
                "
              >
                <el-popover
                  placement="top-start"
                  :title="getReviewerData(currentFormbuilderData).title"
                  width="300"
                  trigger="hover"
                >
                  <div>
                    <span v-if="getReviewerData(currentFormbuilderData).label1"
                      >{{ getReviewerData(currentFormbuilderData).label1 }}
                    </span>
                    <br />
                    <span v-if="getReviewerData(currentFormbuilderData).date"
                      >On:
                      {{
                        getReviewerData(currentFormbuilderData).date
                          | globalDateTimeFormat
                      }}</span
                    >
                    <br />
                    <span
                      v-if="getReviewerData(currentFormbuilderData).reason"
                      style="word-break: break-word"
                    >
                      Reason:
                      {{ getReviewerData(currentFormbuilderData).reason }}
                    </span>
                  </div>
                  <div slot="reference">
                    <i class="el-icon-info"></i>
                  </div>
                </el-popover>
              </div>
            </div>
            <formbuilderLogs
              class="primary"
              :currentFormbuilderData="currentFormbuilderData"
              :allEntitiesData="allEntitiesData"
              :allCompanyUserTypesData="allCompanyUserTypesData"
              v-if="!logsLoading"
            >
            </formbuilderLogs>
          </div>
        </div>
        <div
          v-else-if="!checkAddPermission() && !checkEditPermission()"
          style="margin-right: 20px; margin-bottom: 10px"
        >
          <el-button @click="changeTemplate" size="mini" type="primary">
            <span>Next</span>
          </el-button>
        </div>
        <span
          v-if="isEdit && this.templateData?.templateTryUsingAI"
          class="ai-icon float-right mr-2 ml-1"
        >
          <div class="ai-icon-content" @click="showTryUsingAIMethod">
            <icons :iconName="'TryUsingAI'"></icons>
            <span class="ai-text">Try Using AI</span>
          </div>
        </span>
      </el-row>
    </div>
    <!-- form fields -->
    <dialog-component
      :visible="showAlertFields"
      title="Confirm"
      @before-close="resetAlertFields"
      :containerMaxWidth="'1200px'"
      :containerMaxHeight="'50%'"
    >
      <PrimaryDataForm
        :fieldsListData="alertFormFields"
        :form="form"
        :parentyEntityId="currentEntity._id"
        :templateRules="templateRules"
      >
      </PrimaryDataForm>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showAlertFields = false"
          >Cancel</el-button
        >
        <el-button type="primary" @click="applyRulesAndSaveData(isSaveAndNew)"
          >Ok & Save</el-button
        >
      </span>
    </dialog-component>
    <dialog-component
      :destroy-on-close="true"
      @before-close="reasonForRejectModaldialog"
      :visible="reasonForRejectModal"
      title="Reason for reject"
      :containerWidth="'40%'"
      :containerMaxHeight="'50%'"
    >
      <el-input
        type="textarea"
        :rows="4"
        placeholder="Reason for reject"
        v-model="reasonForReject"
        class="mt-2 mb-2 ml-2"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="rejectFormbuilder(currentFormbuilderData)"
          type="primary"
          :disabled="!reasonForReject.length"
          >Submit</el-button
        >
        <el-button @click="reasonForRejectModal = false">Cancel</el-button>
      </span>
    </dialog-component>
  </section>
</template>

<script>
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import dataTableStylesHelper from "@/mixins/dataTableStylesHelper";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import FieldsFormatHelper from "../../mixins/FieldsFormatHelper";
import axios from "@/config/axios";
import { mapGetters } from "vuex";
// import { bus } from "../../main";
import { makeSlug } from "../../helpers/appHelper";
var fieldsList = require("./fields.json");
import templateConstants from "@/constants/lg-en/templates";
import { postAPICall } from "../../helpers/httpHelper";
import { dataTableAllowedExcecuteFields } from "./formComponentsExecute/index";
import { fetchFilterById } from "@/repo/filtersRepo";
export default {
  props: [
    "isVerticalView",
    "isEmailView",
    "isSplitView",
    "isFormbuilderParent",
    "template",
    "templateData",
    "allTemplatesLength",
    "isExecute",
    "saveAndSwitch",
    "isTabClickedWithoutSaving",
    "isSaveAsDraft",
    "previousSavingTab",
    "saveTemnplateChanges",
    "entityDataId",
    "layout",
    "currentStep",
    "formDataInTabsChange",
    "currentEntity",
    "entitySettings",
    "entityData",
    "isFromQuickAdd",
    "isEdit",
    "refreshKey",
    // "isApplicationUserSide",
    "currentMenu",
    "entityDataExists",
    "hideActions",
    "isApprovalForm",
    "currentFormbuilderData",
    "isUpdatingFormbuilderData",
    "fromAddWidget",
    "fromPopup",
    "formbuilderDetails",
    "companyDetails",
    "profilePicture",
    "type",
    "customization",
    "saveTemp",
    "openNew",
    "suggestionEntityForm",
    "otherEntityForm",
    "allEntitiesDataFromParent",
    "allFormTemplatesFromParent",
    "allCompanyUserTypesDataFromParent",
    "entityFieldsPreferredData",
    "fromScheduling",
    "isFromFormBuilder",
    "getSuggestionFormData",
    "isFromRelationTab",
    "childTableApprovalPermissions",
    "isFromDialog",
    //"category",
  ],
  data() {
    return {
      maskedFields: new Set(),
      originalValues: {},
      isMasked: false,
      initialFormData: {},
      isTabChangeConfirmed: false,
      Mounted: false,
      callingIntegrationExecute: false,
      integrationData: null,
      componentKey: 0,
      category: "",
      // fieldsData: [],
      localCategory: this.category,
      iconSrc: require("@/assets/Enter-Active.svg"),
      showTryUsingAI: false,
      interactiveQuestionsMode: false,
      interactiveQuestionsModeType: "vertical",
      disabledFields: [],
      hideFields: [],
      hasLabel: true,
      fieldsData: [],
      fieldsObject: {},
      loading: false,
      templateStyles: {},
      loadingText: "loading...",
      hasRepeatableTemplate: false,
      formHeight: 600,
      checkedCount: 0,
      templateRules: [],
      entityRepeatableTemplateData: {},
      repeatableTemplateFieldsData: {},
      max_value: 1000,
      min_value: 0,
      singleCompanyTemplateCheckBoxArray: [],
      templateRuleFields: {},
      form: {},
      filesInfo: {},
      entitiesData: [],
      showData: false,
      existedTemplateDataValues: {},
      isApplicationUserSide: false,
      interactiveQuestionsAreDone: false,
      isAllQuestionsAnswered: false,
      alertRules: [],
      alertFields: [],
      alertFormFields: [],
      showAlertFields: false,
      colorFields: {},
      company_id: "",
      savingData: false,
      printEntityData: false,
      downloadEntityData: false,
      isExecuteCopy: false,
      reasonForReject: "",
      reasonForRejectModal: false,
      restrictedRules: [],
      buttonBgColor: "#24a0ed",
      allEntitiesData: [],
      allFormTemplates: [],
      allCompanyUserTypesData: [],
      logsLoading: true,
      isSaveAndNew: false,
      allSelectedDataTableRows: {},
      reFreshTag: true,
      fieldActions: [],
      fieldComponentsMap: {},
      refreshEntityFields: false,
      entitiesToRefresh: [],
      selfTemplateId: "",
      templateName: "",
      suggestedData: {},
      hideOptions: {},
      routeType: "",
      isUploading: false,
      tableHiddenColumns: {},
      integrationFieldValue: null,
      dependentFields: [],
      allFilteredIntegrationVaribleFields: [],
      selectedIntegrationRecord: {},
      isButtonDisabled: true,
      loadingFields: [],
      fieldMap: {
        DATE: "DateExecute",
        FILE: "FileExecute",
        MULTI_LINE_TEXT: "MultiLineTextExecute",
        SINGLE_LINE_TEXT: "SingleLineTextExecute",
        MULTI_SELECT: "MultiSelectExecute",
        NUMBER: "NumberExecute",
        SELECT: "SelectExecute",
        LIST: "ListExecute",
        YES_OR_NO: "YesOrNoExecute",
        HEADING: "HeadingExecute",
        CHECKBOX: "CheckBoxExecute",
        SIGNATURE: "ESignatureExecute",
        GLOBAL_VAIRLABE: "GlobalVariableExecute",
        ENTITY_VARIABLE: "EntityVariableExecute",
        PARAGRAPH: "ParagraphExecute",
        SINGLE_LINE_CONTENT: "SingleLineContentExecute",
        AUTHORIZED_SIGNATURE: "AuthorizedSignatureExecute",
        IMAGE: "ImageExecute",
        FORMULA: "FormulaExecute",
        ACTION_BUTTON: "ActionButtonExecute",
        HTML: "HtmlExecute",
        ENTITY: "EntityExecute",
        CURRENCY: "CurrencyExecute",
        CURRENCY_TYPE: "CurrencyTypesExecute",
        PHONE_COUNTRY_CODE: "PhoneCountryCodeExecute",
        DATE_TIME: "DateTimeExecute",
        DATE_TIME_RANGE: "DateTimeRangeExecute",
        DATE_RANGE: "DateRangeExecute",
        TIME: "TimeExecute",
        RADIO: "RadioExecute",
        WEEKDAYS: "WeekDaysExecute",
        TIME_RANGE: "TimeRangeExecute",
        CHECKBOX_GROUP: "CheckBoxGroupExecute",
        AGGREGATE_FUNCTION: "AggregateFunctionExecute",
        STAR_RATING: "StarRatingExecute",
        DATA_TABLE: "DataTableViewExecute",
        RADIO_BUTTON_GROUP: "RadioButtonGroupExecute",
        AUTO_INCREMENT_NUMBER: "AutoIncrementExecute",
        PAY_BUTTON: "PaymentExecute",
        CONCATENATE: "ConcatenateExecute",
        RANDOM_TEXT: "RandomTextExecute",
        LOCATION: "LocationExecute",
        INTEGRATION: "IntegrationExecute",
        INTEGRATION_VARIABLE: "IntegrationVariableExecute",
      },
      isFromScheduleAction: false,
      getSingleFilterData: null,
    };
  },
  mixins: [
    TemplateBuilderHelper,
    NavigationHelper,
    FormbuilderHelper,
    entityRelationshipMixin,
    dataTableStylesHelper,
    userPermissionsHelper,
    EntityHelper,
    FieldsFormatHelper,
  ],
  components: {
    ...dataTableAllowedExcecuteFields,
    PreviewInteractiveTemplateForm: () =>
      import("@/components/templates/PreviewInteractiveTemplateForm"),
    TemplateFieldsRepeatable: () => import("./templateFieldsRepeatable.vue"),
    PrimaryDataForm: () =>
      import("@/components/entity/PrimaryDataCollectionForm.vue"),
    formbuilderLogs: () => import("@/components/widgets/formbuilderLogsWidget"),
    TryUsingAI: () => import("./formComponentsExecute/TryUsingAI"),
  },
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entities", ["getEntityDataById", "getEntityPrimaryLabels"]),
    ...mapGetters("templatesData", [
      "getRequiredTemplatesData",
      "getDuplicateDataCheck",
      "getUploadAndUpdateDocument",
      "getTemplatesDataUpdateStatus",
      "getUserTemplateDataUpdateErrors",
      "getNewEntityCreateError",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
      "getDuplicateEntityData",
      "getCloningData",
    ]),
    ...mapGetters("auth", ["getUserType"]),
    getSplitViewStyle() {
      if (this.isSplitView) {
        return "overflow-x: hidden;";
      }
      return "";
    },
    rowAlignment() {
      const position =
        this.formbuilderDetails?.customization?.button_horizontal_position;

      if (!position) {
        // If the value is not available, you can return a fallback or keep it undefined
        return "justify-end"; // Default value
      }

      switch (position) {
        case "CENTER":
          return "justify-center";
        case "RIGHT":
          return "justify-end";
        case "LEFT":
          return "justify-start";
        default:
          return "justify-start"; // Fallback if the value is unexpected
      }
    },
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getActiveContactType",
      "getUserTypeList",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("filters", [
      // "getSingleFilterData",
      "getAllEntityFilters",
    ]),
    ...mapGetters("formbuilderData", [
      "getNewWorkflowData",
      "getWorkflowTemplateDataAddStatus",
      "getNewFormbuilderData",
      "getUserFormbuilderData",
      "getFormbuilderDataUpdateStatus",
      "getFormbuilderDataUpdateError",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("entitiesData", ["getCreateDocumentForEntityData", "getSaveAndNew"]),
    ...mapGetters("sso", ["getAuthenticatedSSOToken", "getIsUserLoggedIn"]),
    ...mapGetters("iFrame", ["getIframeUrlInfo"]),
    getStylediv() {
      if (this.getIsMobile) {
        if (
          this.formbuilderDetails?.customization?.button_position == "BOTTOM" ||
          this.allTemplatesLength <= 1
        ) {
          return "height: 90vh;";
        } else if (
          this.formbuilderDetails?.customization?.button_position == "BOTTOM" &&
          this.allTemplatesLength > 1
        ) {
          return "height: 65vh;";
        }
        return "height: 65vh;";
      }
      return `height:${
        this.formHeight + (this.getIsMobile ? this.fieldsData.length * 26 : 200)
      }px;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? ` background: ${this.templateStyles.background} !important;`
          : "";
        borderStyle += ` overflow-y: auto !important;`;
      }
      return borderStyle;
    },
    getFieldsData() {
      return this.fieldsData.map((field) => {
        if (field.key && field.key.includes("#")) {
          field.key = field.key.split("#")[1];
        }
        return field;
      });
    },
    getEntityId() {
      return this.currentEntity?._id || this.$route.params.entity_id;
    },
    getRquiredFieldsCount() {
      return this.fieldsData.filter(
        (e) =>
          e.validations &&
          e.validations.required &&
          this.form[e.key] &&
          this.fieldFilledByCurrentUser(e.filled_by)
      ).length;
    },
    getTotalFieldsCount() {
      return this.fieldsData.filter(
        (e) =>
          e.validations &&
          e.validations.required &&
          !e.value &&
          this.fieldFilledByCurrentUser(e.filled_by)
      ).length;
    },
    entityHasSingleTemplate() {
      return (
        this.currentEntity &&
        this.currentEntity.templates &&
        this.currentEntity.templates.length == 1
      );
    },
    isUserApprovingForm() {
      return this.$route.name == "GuestFormbuilderApprovalExecute";
    },
    isFormbuilderStep() {
      return (
        this.$route?.name == "GuestFormbuilderExecuteUpdateStep" ||
        this.$route?.name == "GuestFormbuilderExecuteStep" ||
        this.$route?.name == "GuestFormbuilderExecute"
      );
    },
    getApprovedUser() {
      // In priority based approvals, only main approver details should be displayed
      if (
        this.currentFormbuilderData?.approval_users &&
        this.currentFormbuilderData?.priority_based
      ) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) =>
            (!this.currentFormbuilderData.priority_based &&
              e.approved_status) ||
            (this.currentFormbuilderData.priority_based &&
              e.approved_status &&
              e.final_approver)
        );
      } else {
        // In non-priority based(all users need to approve) approvals, all approvers details should be displayed, so returning all the approvers
        return this.currentFormbuilderData?.approval_users;
      }
    },
    getRejectedUser() {
      if (this.currentFormbuilderData?.approval_users) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) =>
            (!this.currentFormbuilderData.priority_based &&
              e.rejected_status) ||
            (this.currentFormbuilderData.priority_based &&
              e.rejected_status &&
              e.final_approver)
        );
      }
      return null;
    },
    getCurrentUser() {
      if (this.currentFormbuilderData?.approval_users) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) =>
            (this.getAuthenticatedUser &&
              e.approved_by &&
              e.approved_by.toLowerCase() ==
                this.getAuthenticatedUser.email.toLowerCase()) ||
            (e.rejected_by &&
              e.rejected_by.toLowerCase() ==
                this.getAuthenticatedUser.email.toLowerCase())
        );
      }
      return null;
    },
    hasEditAccess() {
      if (!this.hideActions) {
        return true;
      }
      return (
        this.formbuilderDetails &&
        this.formbuilderDetails.settings &&
        this.formbuilderDetails.settings.edit_responses
      );
    },
    getBrandingStyles() {
      let style = "";
      if (this.hideActions && this.companyDetails?.button?.background) {
        style += "background:" + this.companyDetails.button.background + ";";
      }
      if (this.hideActions) {
        if (this.companyDetails?.button?.button_type === "RECTANGLE") {
          style += "border-radius: 5px;";
        } else {
          style += "border-radius: 50px;";
        }
      }
      return style;
    },
    buttonSize() {
      return (
        this.formbuilderDetails?.customization?.finish_button_size || "mini"
      );
    },
    buttonStyles() {
      return {
        ...this.getBrandingStyles,
        backgroundColor:
          this.formbuilderDetails?.customization?.finish_button_color ||
          "#409EFF",
        borderColor:
          this.formbuilderDetails?.customization?.finish_button_color ||
          "#409EFF",
        color: "#fff",
      };
    },
    allFieldsData() {
      let result = [];
      let allFields = JSON.parse(JSON.stringify(this.fieldsData));
      allFields.forEach((field) => {
        if (
          field.inputType == "DATA_TABLE" ||
          field.input_type == "ENTITY_TABLE"
        ) {
          field.data_table_columns.forEach((el) => {
            el.label = field.label + " - " + el.label;
            result.push(el);
          });
        } else {
          result.push(field);
        }
      });
      return result;
    },
    checkIsDeletedData() {
      if (
        this.entityData?.status == "ARCHIVED" &&
        !this.entityData?.isCreatedFromFormbuilder &&
        !this.isFromFormBuilder
      ) {
        return false;
      }
      return true;
    },
    isFirstTemplate() {
      return (
        this.currentEntity?.templates?.[0]?.template_id ==
        this.templateData?._id
      );
    },
    isButtonLoading() { 
      if(this.hasRepeatableTemplate){
       return false;
      }
      else{
      const relevantFields = this.fieldsData.filter(
        (field) =>
          [
            "ENTITY",
            "ENTITY_VARIABLE",
            "FORMULA",
            "ENTITY_TABLE",
            "TABLE",
          ].includes(field.inputType) && !this.hideFields.includes(field.key)
      );
      if (relevantFields.length === 0) {
        return false;
      }
      return !relevantFields.every((field) =>
        this.loadingFields.includes(field.key)
      );
    }
    },
  },
  created() {
    this.$bus.$on("payButton-fields-validations", (data) => {
      console.log("Data", data);
      let status = this.checkAndShowAlerts();
      console.log("status", status);
      if (status) {
        this.$bus.$off("payButton-fields-validations");
        this.$bus.$emit("payButton-submit", true);
      }
    });

    this.$bus.$on("pay-button-action", (data) => {
      console.log("Paybutton with existing Entity data called", data);
      if (data) {
        this.saveTemplateDataForPayment(data);
      }
      this.$bus.$off("pay-button-action");
    });

    this.$bus.$on("pay-button-action-form-builder-entity", (data) => {
      console.log(
        "pay-button-action-form-builder-entity called from pay button"
      );
      this.saveTemplateData(false, false, true, data);
    });

    this.$bus.$on("save-template-print", (isPrint) => {
      if (isPrint) {
        this.printEntityData = true;
      } else {
        this.downloadEntityData = true;
      }
      if (this.isEdit) {
        this.saveTemplate(false);
      } else {
        this.printEntityDataOnDocument();
      }
    });
    this.$bus.$on("save-template", (openNew) => {
      this.saveTemplate(openNew);
    });
    this.$bus.$on("go-to-edit-mode", () => {
      this.goToEditMode();
    });
    this.$bus.$on("clone-entity-data", () => {
      this.cloneEntityData();
    });
    this.$bus.$on("saveTemplateFromRelationship", (entity_id) => {
      if (entity_id == this.currentEntity._id) {
        this.saveTemplate(false);
      }
    });
    if (this.isFromQuickAdd) {
      this.$bus.$on("saveTemplateFromQuickAdd", (entity_id) => {
        if (entity_id == this.currentEntity._id) {
          this.saveTemplate(false);
        }
      });
    }
    this.$bus.$on("uploadStarted", this.handleUploadStarted);
    this.$bus.$on("uploadFinished", this.handleUploadFinished);
  },
  async mounted() {
    await this.mountedDataMethod();
  },

  beforeDestroy() {
    this.$bus.$off("save-template");
    this.$bus.$off("save-template-print");
    this.$bus.$off("go-to-edit-mode");
    if (!this.isFromQuickAdd) {
      this.$bus.$off("saveTemplateFromRelationship");
    }
    this.$bus.$off("saveTemplateFromQuickAdd");
    this.$store.commit("entities/setAllEntitiesInTemplate", {}, { root: true });
    this.$store.commit("entities/setAllEntities", {}, { root: true });
    this.$store.commit(
      "companyTemplates/setTemplateDataTempVariable",
      {},
      { root: true }
    );
    this.$store.commit(
      "companyTemplates/setAllCompanyTemplatesData",
      { data: [] },
      { root: true }
    );
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    computedStyle(field, index) {
      if (this.isVerticalView) {
        const excludedTypes = [
          "HEADING",
          "HTML_CONTENT",
          "PARAGRAPH",
          "SINGLE_LINE_CONTENT",
          "IMAGE",
          "VIDEO",
          "HORIZONTAL_LINE",
          "CAROUSEL",
          "DIVISION",
        ];

        if (!excludedTypes.includes(field.inputType)) {
          if (!field.styles) {
            field.styles = {}; // Initialize styles if undefined
          }
          if (!("labelStyle" in field.styles)) {
            field.styles.labelStyle = "right";
          }
        }

        if (field.inputType === "HEADING") {
          return {
            gridColumn: "span 100%", // Spans the entire row
          };
        }

        return {
          borderBottom: "1px solid #e5e4e2",
          padding: "2px",
          marginLeft: "2px",
        };
      }

      if (this.isEmailView) {
        return {
          ...this.getExistedStyle(field),
          margin: "5px",
        };
      }

      if (this.isSplitView) {
        return {
          marginLeft: "10px",
          marginTop: "8px",
          width: "96%",
        };
      }

      if (this.getIsMobile) {
        return {}; // Empty object when in mobile view
      }

      return this.getExistedStyle(field) || this.getStyle(field, index);
    },
    async setDataToIntegrationVariables(
      selectedRecord,
      integrationField,
      allApplications
    ) {
      try {
        let integrationVaribleFields = this.fieldsData.filter(
          (field) =>
            field.inputType == "INTEGRATION_VARIABLE" &&
            field.integration_settings.selectedIntegrationField ==
              integrationField.key
        );
        if (integrationVaribleFields.length) {
          if (this.form[integrationField.key]) {
            this.allFilteredIntegrationVaribleFields = integrationVaribleFields;
            let applicationSelected =
              integrationField.integration_settings.selectedApplication;
            let application = allApplications.find(
              (selectedApp) => selectedApp._id === applicationSelected
            );
            let events = application.events;
            let event = integrationField.integration_settings.selectedEvent;
            let eventDetails = events.find(
              (selectedEvent) => selectedEvent._id === event
            );
            if (eventDetails.settings.eachRecordByEvent) {
              let variablesData = await this.getVariblesDataByIntegration(
                integrationField,
                eventDetails,
                events,
                application
              );
              this.selectedIntegrationRecord = variablesData;
            } else {
              this.selectedIntegrationRecord = selectedRecord;
            }
            this.integrationData = this.form[integrationField.key];
          } else {
            integrationVaribleFields.forEach((field) => {
              this.$set(this.form, field.key, "");
            });
          }
        }
        let dependencyFields = this.fieldsData.filter(
          (field) =>
            field.integration_settings &&
            field.integration_settings.templateDependencies &&
            field.integration_settings.templateDependencies.includes(
              integrationField.key
            )
        );
        if (dependencyFields.length) {
          const depFieldKeys = dependencyFields.map((depField) => depField.key);
          this.fieldsData.forEach((field) => {
            if (
              field.inputType === "INTEGRATION_VARIABLE" &&
              depFieldKeys.includes(
                field.integration_settings.selectedIntegrationField
              )
            ) {
              this.$set(this.form, field.key, "");
            }
          });
        }
      } catch (err) {
        console.log("Err", err);
      }
    },
    setDataToIntegrationField(formValue, triggerField) {
      try {
        this.dependentFields = [];
        this.fieldsData.forEach((field) => {
          if (field.integration_settings) {
            let dependencies = field.integration_settings.templateDependencies;
            if (
              field.integration_settings.templateDependencies.includes(
                triggerField.key
              )
            ) {
              let allDependecies = dependencies.every((dep) => this.form[dep]);
              if (allDependecies) {
                this.dependentFields.push(field.key);
                this.integrationFieldValue = formValue;
              }
            }
          }
        });
      } catch (err) {
        console.log("errrr", err);
      }
    },
    async mountedDataMethod() {
      this.isExecuteCopy = this.isExecute;
      this.logsLoading = true;
      if (fieldsList) {
        this.fieldActions = [
          ...fieldsList.allFormFields,
          ...fieldsList.allContentFields,
          ...fieldsList.allAdvancedFields,
        ];
        this.fieldActions.forEach((field) => {
          if (!this.fieldComponentsMap[field.inputType]) {
            this.fieldComponentsMap[field.inputType] = field.components;
          }
        });
      }
      this.logsLoading = false;
      this.selfTemplateId = this.templateData?._id;
      this.templateName = this.templateData?.name;
      this.setTemplateInfo(this.templateData);
      if (this.$refs.formBuilder && this.$refs.formBuilder.clientWidth) {
        this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
      }
      if (
        [
          "ApEntityExecute",
          "ApPreviewEntityData",
          "application-user-dashboard",
        ].includes(this.$route.name)
      ) {
        this.isApplicationUserSide = true;
      } else {
        this.routeType = "ENTITY";
        this.category = "ENTITY";
      }
      if (!this.checkAddPermission() && !this.checkEditPermission()) {
        this.isExecuteCopy = false;
      }
      if (!this.entityDataId) {
        this.showData = true;
      }
      await this.checkUrlType();
      [
        this.allCompanyUserTypesData,
        this.allEntitiesData,
        this.allFormTemplates,
        this.getSingleFilterData,
      ] = await Promise.all([
        this.allCompanyUserTypesDataFromParent
          ? this.allCompanyUserTypesDataFromParent
          : this.fetchCompanyUsersTypes(),
        this.allEntitiesDataFromParent
          ? this.allEntitiesDataFromParent
          : this.fetchAllEntities(),
        this.allFormTemplatesFromParent
          ? this.allFormTemplatesFromParent
          : this.fetchAllTemplates(),
        this.$route.query?.filter
          ? fetchFilterById(this.$route.query.filter)
          : Promise.resolve(),
      ]);
      if (this.$route.query.index) {
        if (
          this.templateData._id == this.$route.query.board_field.split("#")[0]
        ) {
          this.$set(
            this.form,
            this.$route.query.board_field.split("#")[1],
            this.$route.query.index
          );
        }
      }
      window.addEventListener("resize", this.handleResize);
    },
    validateSingleLineText(text, type) {
      let typesMap = {
        HYPER_LINK: "hyperlinkPattern",
        EMAIL: "emailPattern",
        PASSWORD: "passwordPattern",
        SSN: "ssnPattern",
        "CREDIT CARD": "creditCardPattern",
      };
      text = ["SSN", "CREDIT CARD"].includes(type)
        ? text.replace(/ /g, "").replace(/-/g, "")
        : text;
      let valid = templateConstants[typesMap[type]].test(text);
      return valid;
    },
    goEditMode() {
      this.$emit("onEdit");
    },
    checkRolePermission(permission) {
      if (this.getUserType?.permissionSet && !this.isApplicationUserSide) {
        return this.getUserType.permissionSet.includes(permission);
      }
      return true;
    },
    updateHiddenColumns(parentKey, columns) {
      this.$set(this.tableHiddenColumns, parentKey, columns);
    },
    getAutoFillEntityData(field) {
      if (field?.key && this.entityFieldsPreferredData?.[field.key]) {
        return this.entityFieldsPreferredData[field.key];
      }
      return null;
    },
    handleCopyContent({ fieldKey, dataToCopy }) {
      if (fieldKey) {
        this.$set(this.form, fieldKey, dataToCopy);
        this.$emit("update-template-info", { fieldKey, dataToCopy });
      } else {
        this.$message.warning("No output field specified.");
      }
    },
    getContainerStyle(style) {
      // if (this.layout == "STANDARD") {
      //   return "max-height: calc(100vh - 550px) !important";
      // }

      if (style === "FULLSCREEN" && !this.isEmailView) {
        // Find the last element and its 'y' value
        // const lastElement = this.fieldsData[this.fieldsData.length - 1];
        // const lastElementY = lastElement?.y || 0; // Safely access 'y' or default to 0
        // const calculatedHeight = lastElementY + 40;

        // Return the modified max-height
        return `max-height: calc(87vh) !important; ${this.getElementStyle}`;
      }
      return `min-height: calc(100vh - 400px) !important; max-height: calc(100vh - 175px) !important; ${this.getElementStyle}`;
    },
    showTryUsingAIMethod() {
      this.showTryUsingAI = true;
    },
    handleCloseTryUsingAI() {
      this.showTryUsingAI = false;
    },
    getPopUpStyle(style) {
      if (style == "FULLSCREEN" && !this.isEmailView) {
        return `height:87vh; scrollbar-width:thin;` + this.getElementStyle;
      }
      return `height:74vh;` + this.getElementStyle;
    },
    actionButtonCallBacks(action) {
      if (action.call_back == "CLOSE_PARENT") {
        this.$emit("closeParent");
      } else if (action.call_back == "UPDATE_PARENT") {
        this.saveTemplate(false);
      } else if (action.call_back == "REFRESH_PARENT") {
        if (
          action.selected_other_entity &&
          !this.entitiesToRefresh.includes(action.selected_other_entity)
        ) {
          this.entitiesToRefresh.push(action.selected_other_entity);
        }
        this.refreshEntityFields = false;
        setTimeout(() => {
          this.refreshEntityFields = true;
        });
      }
    },
    saveTemplateFromActionButton() {
      this.saveTemplate(false, true);
    },
    getFieldLabelFromRules(field) {
      if (this.labelsData && this.labelsData[field.key]) {
        return this.labelsData[field.key];
      }
      if (this.isEmailView) {
        const parts = field.label.split("-");
        const result = parts.slice(1).join("-");
        return result;
      }
      return field.label;
    },
    convertToLocalDateTime(dateTime) {
      const date = new Date(dateTime);
      return date.toLocaleString();
    },
    getSaveButtonLabel() {
      if (this.hideActions) {
        if (this.currentStep?.has_next) {
          if (this.entityDataId) {
            return this.customization &&
              this.customization.buttons &&
              this.customization.buttons[4].value
              ? this.customization.buttons[4].value
              : "Update & Next";
          } else {
            return "Save & Next";
          }
        } else {
          if (this.formbuilderDetails?.customization?.finish_button_label) {
            return this.formbuilderDetails.customization.finish_button_label;
          }
          return "Save";
        }
      } else {
        return this.currentStep &&
          this.currentStep.has_next &&
          this.entityDataId
          ? this.customization &&
            this.customization.buttons &&
            this.customization.buttons[4].value
            ? this.customization.buttons[4].value
            : "Update & Next"
          : this.currentStep && this.currentStep.has_next && !this.entityDataId
          ? this.customization &&
            this.customization.buttons &&
            this.customization.buttons[1].value
            ? this.customization.buttons[1].value
            : "Save & Next"
          : this.customization &&
            this.customization.buttons &&
            this.customization.buttons[0].value
          ? this.customization.buttons[0].value
          : "Save";
      }
    },
    checkFormbuilderStatus(status) {
      if (this.currentFormbuilderData?.approval_status && status) {
        return this.currentFormbuilderData.approval_status == status;
      }
      return false;
    },
    reasonForRejectModaldialog() {
      this.reasonForRejectModal = false;
    },
    showRejectConfirm() {
      let childTableFields = this.fieldsData.filter(
        (fd) => fd.input_type == "ENTITY_TABLE"
      );
      if (childTableFields?.length) {
        for (let tb of childTableFields) {
          let tableData = this.form[tb?.key];
          let missingApproval = tableData.find((dt) => !dt?.approval_status);
          let key = tb.entity_id + "#" + tb.key;
          let foundApprovalPermission =
            this.childTableApprovalPermissions?.findIndex(
              (tableKey) => tableKey == key
            );
          if (missingApproval && foundApprovalPermission > -1) {
            let index = tableData.indexOf(missingApproval);
            this.$notify.warning({
              title: "Warning",
              message: `You have not approved or rejected the ${
                tb.label
              } data on the row ${index + 1}`,
            });
            return;
          }
        }
      }
      this.reasonForReject = "";
      this.reasonForRejectModal = true;
    },
    async rejectFormbuilder(formbuilderData) {
      this.loading = true;
      this.reasonForRejectModal = false;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getRejectParams(
          formbuilderData,
          currentuserIndex,
          this.reasonForReject
        );
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Rejected successfully!",
            type: "success",
          });
          this.reasonForReject = "";
          //have to change the action
          window.location.reload();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while rejecting",
          });
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    async onApprove() {
      let childTableFields = this.fieldsData.filter(
        (fd) => fd.input_type == "ENTITY_TABLE"
      );
      if (childTableFields?.length) {
        for (let tb of childTableFields) {
          let tableData = this.form[tb?.key];
          let missingApproval = tableData.find((dt) => !dt?.approval_status);
          let key = tb.entity_id + "#" + tb.key;
          let foundApprovalPermission =
            this.childTableApprovalPermissions?.findIndex(
              (tableKey) => tableKey == key
            );
          if (missingApproval && foundApprovalPermission > -1) {
            let index = tableData.indexOf(missingApproval);
            this.$notify.warning({
              title: "Warning",
              message: `You have not approved or rejected the ${
                tb.label
              } data on the row ${index + 1}`,
            });
            return;
          }
        }
      }
      this.$confirm("Are you sure to approve?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.approveFormbuilder(this.currentFormbuilderData);
      });
    },
    async approveFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getApprovalParams(formbuilderData, currentuserIndex);
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Approved successfully!",
            type: "success",
          });
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving",
          });
        }
        //have to change the action
        window.location.reload();
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    async printEntityDataOnDocument(dataId) {
      if (this.currentEntity && this.currentEntity.default_printing_document) {
        this.loading = true;
        this.loadingText = "Generating document...";
        await this.$store.dispatch(
          "entitiesData/createDocumentFromEntityData",
          {
            entity_data_id: dataId ? dataId : this.entityDataId,
            entity_id: this.currentEntity._id,
            document_id: this.currentEntity.default_printing_document || "",
            custom_message:
              this.currentEntity?.pdf_printing_info?.custom_message || "",
          }
        );
        if (
          this.getCreateDocumentForEntityData &&
          this.getCreateDocumentForEntityData.generatedDocuments
        ) {
          this.loading = false;
          this.loadingText = "";
          if (this.printEntityData) {
            this.openGeneratedPdf(
              this.getCreateDocumentForEntityData.generatedDocuments[0]
            );
          } else {
            this.downloadEntityDataPdf(
              this.getCreateDocumentForEntityData.generatedDocuments,
              this.getCreateDocumentForEntityData?.doc_printing_names.length &&
                this.getCreateDocumentForEntityData.doc_printing_names[0]
                ? this.getCreateDocumentForEntityData.doc_printing_names[0].trim()
                : this.currentEntity && this.currentEntity.name
                ? this.currentEntity.name
                : "Data "
            );
          }
          this.printEntityData = false;
          this.downloadEntityData = false;
        } else {
          this.loadingText = "";
          this.loading = false;
          this.printEntityData = false;
          this.downloadEntityData = false;
          this.$message({
            message: "Error while generating document",
            type: "error",
          });
        }
      } else {
        this.printEntityData = false;
        this.downloadEntityData = false;
      }
    },
    async openGeneratedPdf(printingUrl) {
      const response = await fetch(printingUrl);
      const pdfData = await response.arrayBuffer();
      const blob = new Blob([pdfData], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = url;
      iframe.onload = function () {
        iframe.contentWindow.print();
      };
      document.body.appendChild(iframe);
    },
    async duplicateDataRules() {
      if (this.hasRepeatableTemplate) {
        return true;
      }
      let checkData = await this.checkDataTableDuplicateData(
        this.fieldsData,
        this.form
      );
      if (checkData?.success) {
        if (
          !this.templateData?.all_duplicate_rules ||
          !this.templateData.all_duplicate_rules.length
        ) {
          return true;
        }
        this.loading = true;
        this.loadingText = "Checking duplicate data...";
        await this.$store.dispatch("templatesData/checkDuplicateData", {
          data: [
            {
              form: this.form,
              template_id: this.templateData._id,
            },
          ],
          entity_id: this.getEntityId,
          parent_entityDataId: null,
        });
        if (this.getDuplicateDataCheck) {
          if (!this.getDuplicateDataCheck?.allowed) {
            this.$notify.warning({
              title: "Warning",
              message:
                "With this data already " +
                (this.currentEntity && this.currentEntity.name
                  ? this.currentEntity.name + " is"
                  : "data is") +
                " created.",
            });
            this.loading = false;
            return false;
          }
          return true;
        } else {
          this.loading = false;
          this.$message({
            message: "Error while checking data",
            type: "error",
          });
          return false;
        }
      } else {
        this.$message({
          message: checkData.message || "Error while checking data",
          type: "error",
        });
        return false;
      }
    },
    async cloneEntityData() {
      let checkData = this.checkAndShowAlerts();
      if (checkData) {
        let duplicateCheck = await this.duplicateDataRules();
        if (duplicateCheck) {
          this.loading = true;
          this.loadingText = "Duplicating the data...";
          let form = {};
          if (this.hasRepeatableTemplate) {
            let key = "";
            if (
              this.entityRepeatableTemplateData &&
              this.entityRepeatableTemplateData.templateInfo &&
              this.entityRepeatableTemplateData.templateInfo.name
            ) {
              let name = this.entityRepeatableTemplateData.templateInfo.name;
              key = name.toLowerCase().split(" ").join("_");
            } else {
              key = "templateData";
            }
            form[key] = this.entitiesData.map((e) => {
              if (e.isNewData) {
                delete e.isNewData;
              }
              return e;
            });
          } else {
            //  form = this.form;
            let incrementFields = this.fieldsData.filter(
              (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
            );

            if (incrementFields && incrementFields.length) {
              await incrementFields.map(async (field) => {
                this.form[`${field.key}_info`] = {
                  number: field.auto_increment_starting_number,
                  prefix: field.auto_increment_prefix,
                };
              });
            }
            form = await this.prepareFormData();
          }
          let existedData = {};
          (this.entityData.templates_data || []).forEach((tem) => {
            if (tem?.template_data_id?.template_data) {
              existedData[tem.template_id] =
                tem?.template_data_id?.template_data;
            }
            if (tem.template_id == this.templateData._id) {
              existedData[tem.template_id] = { ...form };
            }
          });
          let allFields = this.getAllFieldsFormEntity(this.currentEntity);
          allFields.forEach((e) => {
            let key = e.key;
            if (key && key.includes("#")) {
              key = key.split("#")[1];
            }
            if (
              e?.properties?.filed_content == "Hide" &&
              existedData?.[e.template_id]?.[key]
            ) {
              existedData[e.template_id][key] = "";
            }
            if (e.default_value) {
              if (existedData[e.template_id]) {
                if (existedData[e.template_id][key]) {
                  existedData[e.template_id][key] = e.default_value;
                } else {
                  existedData[e.template_id] = {
                    ...existedData[e.template_id],
                    [key]: e.default_value,
                  };
                }
              } else {
                existedData[e.template_id] = {
                  [key]: e.default_value,
                };
              }
            }
            if (
              e.input_type == "DATA_TABLE" &&
              existedData?.[e.template_id]?.[key]
            ) {
              let tData = existedData[e.template_id][key];
              existedData[e.template_id][key] = (tData || []).map((el) => {
                (e.data_table_columns || []).forEach((f) => {
                  if (f?.properties?.filed_content == "Hide" && el?.[f.key]) {
                    el[f.key] = "";
                  }
                  if (f?.default_value) {
                    el[f.key] = f.default_value;
                  }
                });
                el.keyIndex = (Math.random() + 1).toString(36).substring(7);
                return el;
              });
            }
            if (e.input_type == "DATE" && e?.properties?.autofill_response) {
              existedData[e.template_id][key] = this.getCurrentDate;
            }
          });
          let keyIndex = (Math.random() + 1).toString(36).substring(7);
          existedData["keyIndex"] = keyIndex;
          await this.$store.commit(
            "templatesData/setCloningData",
            { [this.currentEntity._id]: existedData },
            {
              root: true,
            }
          );
          let query = this.getNavigationQuery(this.$route.query);
          query["dataId"] = "";
          query["stepId"] = 0;
          query["key"] = keyIndex;
          query["newData"] = true;
          query["type"] = "s7aLF3NnAQg=";
          this.$router.push({
            name: this.$route.name,
            query: query,
          });
          this.refreshThePage(true);
        }
      }
    },
    async modifiedFormData() {
      let form = {};
      if (this.hasRepeatableTemplate) {
        let key = "";
        if (
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.templateInfo &&
          this.entityRepeatableTemplateData.templateInfo.name
        ) {
          let name = this.entityRepeatableTemplateData.templateInfo.name;
          key = name.toLowerCase().split(" ").join("_");
        } else {
          key = "templateData";
        }
        form[key] = this.entitiesData.map((e) => {
          if (e.isNewData) {
            delete e.isNewData;
          }
          return e;
        });
      } else {
        //  form = this.form;
        let incrementFields = this.fieldsData.filter(
          (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
        );

        if (incrementFields && incrementFields.length) {
          await incrementFields.map(async (field) => {
            this.form[`${field.key}_info`] = {
              number: field.auto_increment_starting_number,
              prefix: field.auto_increment_prefix,
            };
          });
        }
        // this.form = this.mapDateValues(this.fieldsData, this.form);
        form = await this.prepareFormData();
        //Update parent entity data by entity variable - mixin method
        if (!this.isApprovalForm) {
          await this.updateParentDataByEntityVariable(
            this.fieldsData,
            this.form
          );
        }
        if (
          form &&
          form.first_name &&
          form.last_name &&
          form.first_name.length &&
          form.last_name.length &&
          !form.name
        ) {
          form.name = form.first_name + " " + form.last_name;
        }
      }
      return form;
    },
    async duplicateEntityData() {
      let checkData = this.checkAndShowAlerts();
      if (checkData) {
        let duplicateCheck = await this.duplicateDataRules();
        if (duplicateCheck) {
          this.loading = true;
          this.loadingText = "Duplicating the data...";
          let form = await this.modifiedFormData();
          let data = {
            template_id: this.templateData._id,
            template_data: form,
            entity_id: this.currentEntity._id,
            existed_data_id: this.entityDataId,
          };
          await this.$store.dispatch("templatesData/duplicateEntityData", data);
          if (this.getDuplicateEntityData?._id) {
            this.checkAndSetRelationship(this.getDuplicateEntityData?._id);
            let query = this.getNavigationQuery(this.$route.query);
            query["dataId"] = this.getDuplicateEntityData._id;
            await this.updateEntitiesData(this.getDuplicateEntityData?._id);
            let tempQuery = {};
            if (this.$route?.query?.filter) {
              tempQuery["filter"] = this.$route.query.filter;
            }
            if (this.$route?.query?.viewType) {
              tempQuery["viewType"] = this.$route.query.viewType;
            }
            if (this.isApplicationUserSide) {
              let menu_id = this.$route?.query?.menu_id;
              this.$router.push({
                path: "/ap/entity/" + this.currentEntity._id + "/" + menu_id,
                query: tempQuery,
              });
            } else {
              let UrlPath = "";
              if (this.getIframeUrlInfo != null) {
                UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/${this.currentEntity._id}`;
              } else {
                UrlPath = `/entity/${this.currentEntity._id}`;
              }
              this.$router.push({
                path: UrlPath,
                query: tempQuery,
              });
            }
          } else {
            this.loading = false;
            this.$notify.error({
              title: "Error",
              message:
                "Error while duplicating " +
                (this.currentEntity && this.currentEntity.name
                  ? this.currentEntity.name
                  : "data"),
            });
          }
        }
      }
    },
    resetAlertFields() {
      this.showAlertFields = false;
    },
    updateInteractiveQuestionFillingStatus(status) {
      if (!this.entityDataId) {
        if (status == true && this.interactiveQuestionsAreDone) {
          this.$emit("allFieldsCompleted", true);
          this.isAllQuestionsAnswered = true;
        }
        this.interactiveQuestionsAreDone = status;
      }
    },
    getExistedStyle(field) {
      return field.style || undefined;
    },
    getExistedSplitViewStyle(field) {
      field.width = 30;
      return field.style;
    },
    updateTopForBelowElements(data) {
      this.fieldsData.forEach((e, index) => {
        if (e.listen_height) {
          if (data) {
            this.$set(
              this.fieldsData[index],
              "style",
              this.getStyle(e, index, data.height)
            );
          } else {
            this.$set(this.fieldsData[index], "style", this.getStyle(e, index));
          }
        }
      });
    },
    checkIsMinValue(data) {
      if (data && data.min_selection > 0) {
        return true;
      } else {
        return false;
      }
    },
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      }
      return data?.validations?.required ? true : false;
    },
    updateTableFieldData(details) {
      this.$set(this.form, details.key, details.data);
      this.$set(
        this.form,
        details.key + "/Onloaded",
        !this.form[details.key + "/Onloaded"]
      );
    },

    checkPerimission(access) {
      //If approver user
      if (this.isUserApprovingForm) {
        return false;
      }
      if (this.isApplicationUserSide) {
        if (this.getSingleFilterData) {
          if (this.getSingleFilterData.permissions.access_set.length) {
            if (access == "EDIT") {
              access = "UPDATE";
            }
            return (
              this.getSingleFilterData.permissions.access_set.indexOf(
                access
              ) !== -1
            );
          }
        }
        if (this.getEntityPermission) {
          if (access == "UPDATE") {
            access = "EDIT";
          }

          if (this.currentMenu) {
            if (
              this.currentMenu?.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              this.templateData?._id &&
              this.getEntityPermission.other_entities[this.currentEntity._id]
                ?.templates?.[this.templateData._id] &&
              this.getEntityPermission.other_entities[
                this.currentEntity._id
              ]?.templates?.[this.templateData._id].indexOf(access) === -1
            ) {
              return false;
            } else if (
              this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
              this.getEntityPermission.relational_entities &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.permissions &&
              this.getEntityPermission.relational_entities[
                this.currentEntity._id
              ]?.templates?.[this.templateData._id].indexOf(access) === -1
            ) {
              return false;
            } else if (
              this.currentMenu?.entity_type == "NESTED_RELATIONAL_ENTITY" &&
              this.currentMenu?.nested_relational_entity &&
              this.getEntityPermission.nested_relational_entities &&
              this.getEntityPermission.nested_relational_entities[
                this.currentMenu.nested_relational_entity
              ]?.templates?.[this.templateData._id].indexOf(access) === -1
            ) {
              return false;
            }
          } else if (this.$route.query.fromCustom) {
            if (
              this.getEntityPermission?.other_entities &&
              this.getEntityPermission?.other_entities[
                this.currentEntity._id
              ]?.templates?.[this.templateData._id].indexOf(access) === -1
            ) {
              return false;
            }
            return true;
          }
        }
        return true;
      } else {
        if (access == "EDIT") {
          access = "UPDATE";
        }
        if (
          this.$route.query?.filter &&
          this.getSingleFilterData &&
          this.currentEntity &&
          this.currentEntity._id == this.getSingleFilterData.entity_id &&
          this.getSingleFilterData?.permissions?.access_set
        ) {
          if (!this.getSingleFilterData.permissions.access_set.length) {
            return true;
          }
          return (
            this.getSingleFilterData.permissions.access_set.indexOf(access) !==
            -1
          );
        }
        if (access == "DUPLICATE" && this.isFormbuilderStep) {
          return false;
        }
        return true;
      }
    },
    goToEditMode() {
      //if (this.entityDataExists) {
      // this.$confirm(
      //   "You can't edit the existed " + this.currentEntity.name,
      //   "Warning",
      //   {
      //     confirmButtonText: "Go back",
      //     cancelButtonText: "Cancel",
      //     type: "warning",
      //   }
      // )
      //   .then(() => {
      //     this.backToEntityInfoList();
      //   })
      //   .catch(() => {
      //   });
      // return;
      //}
      //form builder edit mode
      if (this.hideActions) {
        this.$router.push({
          name: "GuestFormbuilderExecute",
          params: {
            formbuilder_details_id: this.$route.params.formbuilder_details_id,
          },
          query: {
            formbuilder_data_id: this.$route.query.formbuilder_data_id,
            stepId: 0,
          },
        });
        window.location.reload();
      } else {
        let url = this.urlBuilder(this.$route.query);
        if (this.isApplicationUserSide) {
          url = `/ap/entity-execute/${this.currentEntity._id}?` + url;
        } else {
          let UrlPath = "";
          if (this.getIframeUrlInfo != null) {
            UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.currentEntity._id}?`;
          } else {
            UrlPath = `/entity/edit/${this.currentEntity._id}?`;
          }
          url = UrlPath + url;
        }
        if (!url.includes("type=AUfYWb7SC6U")) {
          url += "&type=AUfYWb7SC6U%3D";
        }
        if (this.reFreshTag == true) {
          //window.location.href = url;
          this.$router.push(url);
        }
      }
    },
    isEmpty(field, form) {
      if (
        ["DATA_TABLE", "ENTITY_TABLE"].includes(field.inputType) &&
        form[field.key] &&
        form[field.key].length
      ) {
        return false;
      }
      return [null, undefined, ""].includes(form[field.key]) ? true : false;
    },
    async setDataToEntityVariables(
      data,
      parent,
      label,
      changed,
      parentDisabled = false
    ) {
      // let entityId = data?._id ? data._id : null;
      if (label && data) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.getFieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          (field.inputType == "ENTITY" ||
            field.input_type == "ENTITY_VARIABLE") &&
          (field.field_assignable == "read_only" ||
            this.isEmpty(field, this.form) ||
            (changed &&
              (field.overwrite_on_change || this.isEmpty(field, this.form))))
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // because we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }
            if (parent?.allow_multiple) {
              if (data.length) {
                let dataValues = [],
                  result = 0,
                  min,
                  fileVal = [];
                let existedIds = (
                  this.form &&
                  this.form[field.key] &&
                  this.form[field.key].length
                    ? this.form[field.key]
                    : []
                )
                  .filter((e) => e.parentDataId)
                  .flatMap((dt) => dt.parentDataId);
                (data || []).forEach((e) => {
                  if (
                    e?.entityData?.[templateId]?.[key] &&
                    existedIds.indexOf(e._id) == -1
                  ) {
                    if (field.inputType == "FILE") {
                      fileVal.push(e.entityData[templateId][key]);
                    }
                    if (field.inputType == "DATA_TABLE") {
                      if (e?.entityData?.[templateId]?.[key].length) {
                        e.entityData[templateId][key].forEach((dt) => {
                          dataValues.push({
                            ...dt,
                            ...{
                              parentDataId: e._id,
                              parentDataName: e.parentDataName,
                            },
                          });
                        });
                      }
                    } else if (this.isNumber(e.entityData[templateId][key])) {
                      let v = e.entityData[templateId][key];
                      if (
                        field.variable_action == "SUM" ||
                        field.variable_action == "AVERAGE"
                      ) {
                        result = result + parseInt(v);
                      } else if (
                        field.variable_action == "HIGHEST" &&
                        v > result
                      ) {
                        result = parseInt(v);
                      } else if (field.variable_action == "LOWEST") {
                        if (v < min || min == undefined) {
                          min = parseInt(v);
                        }
                      }
                    }
                  }
                });
                if (
                  parent.allow_multiple &&
                  parent.detailed_view &&
                  parentDisabled
                ) {
                  dataValues = [...(this.form[field.key] || [])];
                } else if (existedIds.length) {
                  dataValues = [...dataValues, ...(this.form[field.key] || [])];
                }
                if (field.inputType == "DATA_TABLE") {
                  this.$set(this.form, field.key, dataValues);
                } else if (field.inputType == "FILE") {
                  this.$set(this.form, field.key, fileVal);
                  this.form[field.key] = fileVal;
                } else {
                  if (field.variable_action == "AVERAGE") {
                    let avg = result / data.length;
                    this.$set(this.form, field.key, avg);
                  } else if (field.variable_action == "LOWEST") {
                    this.$set(this.form, field.key, min);
                  } else {
                    this.$set(this.form, field.key, result);
                  }
                }
              }
            } else {
              if (
                data &&
                data.entityData &&
                data.entityData[templateId] &&
                data.entityData[templateId][key]
              ) {
                value = data.entityData[templateId][key];
                if (
                  field.inputType == "ENTITY" ||
                  field.inputType == "INTEGRATION" ||
                  field.inputType == "INTEGRATION_VARIABLE"
                ) {
                  valueName = data.entityData[templateId][key + "/name"];
                  if (typeof value == "object" && value.length) {
                    this.$set(this.form, field.key + "/default", value);
                  } else {
                    this.$set(this.form, field.key + "/default", [value]);
                  }
                }
              }

              if (this.form[field.key]) {
                if (
                  data &&
                  data.entityData &&
                  data.entityData[templateId]?.[key + "_code"]
                ) {
                  this.form[field.key + "_code"] =
                    data.entityData[templateId][key + "_code"];
                } else if (
                  data &&
                  data.entityData &&
                  data.entityData[templateId][key + "_info"]
                ) {
                  this.form[field.key + "_info"] =
                    data.entityData[templateId][key + "_info"];
                }
                // this.form[field.key] = value;
                this.$set(this.form, field.key, value);
                if (valueName) {
                  this.form[field.key + "/name"] = valueName;
                }
              } else {
                this.$set(this.form, field.key, value);
                if (
                  data &&
                  data.entityData &&
                  data.entityData[templateId]?.[key + "_code"]
                ) {
                  this.form[field.key + "_code"] =
                    data.entityData[templateId][key + "_code"];
                }
                if (valueName) {
                  this.$set(this.form, field.key + "/name", valueName);
                }
              }

              if (field.global_variable_pay_field) {
                //logic for Payment Variable fields
                this.$set(
                  this.form,
                  field.global_variable_entity_select_type_field_key +
                    "_" +
                    field.global_variable_pay_field,
                  data.entityData[templateId][
                    field.global_variable_entity_select_type_field_key +
                      "_" +
                      field.global_variable_pay_field
                  ]
                );
              }
              if (
                field.inputType == "PAY_BUTTON" ||
                field.input_type == "PAY_BUTTON"
              ) {
                // pay button data set block

                this.form[field.key + "_info"] =
                  data.entityData[templateId][
                    field.global_variable_entity_select_type_field_key + "_info"
                  ];
                this.form[field.key + "_history"] =
                  data.entityData[templateId][
                    field.global_variable_entity_select_type_field_key +
                      "_history"
                  ];
              }
            }
          }
        }
        //needs to verify this
        else if (
          field.relationship_key == parent.key &&
          field.global_variable_entity_field &&
          !parent?.allow_multiple
        ) {
          let templateId = "";
          let key = "";
          let value = "";
          let valueName = "";
          if (field.global_variable_entity_field.includes("#")) {
            templateId = field.global_variable_entity_field.split("#")[0];
            key = field.global_variable_entity_field.split("#")[1];
          } else {
            templateId = field.global_variable_entity_field;
          }
          if (
            data &&
            data.entityData &&
            data.entityData[templateId] &&
            data.entityData[templateId][key]
          ) {
            value = data.entityData[templateId][key];

            if (field.inputType == "ENTITY") {
              valueName = data.entityData[templateId][key + "/name"];
              this.$set(this.form, field.key + "/name", valueName);
              if (typeof value == "object" && value.length) {
                this.$set(this.form, field.key + "/default", value);
              } else {
                this.$set(this.form, field.key + "/default", [value]);
              }
            } else {
              this.$set(this.form, field.key, value);
            }
          }
        }
      });
      this.$bus.$emit("out-side-entity-update", data, parent, label, changed);
      this.rulesEventEmitter();
    },
    async unsetEntityVariables(parent) {
      this.getFieldsData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          if (field?.inputType == "DATA_TABLE") {
            this.$set(this.form, field.key, []);
          } else {
            this.$set(this.form, field.key, "");
          }
        }
      });
      this.rulesEventEmitter();
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (field.inputType == "NUMBER" && !isNaN(data[field.key])) {
          return false;
        } else if (data && data[field.key] && data[field.key].length) {
          return false;
        }
        return true;
      }
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    checkRequiredFields() {
      this.checkedCount = 0;
      this.requiredFields = [];
      // While adding repeatable template data, added data is getting empty array, so i have added this condition.
      if (!this.hasRepeatableTemplate) {
        this.entitiesData = [];
      }
      this.minValue = "";
      this.fileMinValue = "";
      this.fileMaxValue = "";
      let showError = false;
      let invalidError = false;
      let emailFormatError = false;
      let checkboxMinMaxCheck = false;
      let fileMinMaxCheck = false;
      let customErrorMessage = "";
      let checkBoxLabel = "";
      let multiselectMinMaxCheck = false;
      let multiselectLabel = "";
      let timerangeLabel = "";
      let yesornoLabel = "";
      let reZipCode =
        /^(?:\d{4}|22\d{3}|CC-\d{5}|[A-Z]{2}\d{3}|AI-2640|[A-Z]\d{4}[A-Z]{3}|\d{5}-\d{4}|\d{5}|[A-Z]{2} \d{4}|\d{6}|[A-Z]{2}-\d{4}|\d{4} [A-Z]{2}|[A-Z]{3} \d{4}|\d{3} \d{2}|[A-Z]{1,2}\d{1,2} \d[A-Z]{2})$/;
      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.hasRepeatableTemplate) {
        this.entitiesData.push({ ...this.form });
      }
      this.entitiesData.forEach((data) => {
        this.fieldsData.forEach((field) => {
          if (typeof customErrorMessage === "undefined") {
            customErrorMessage = "";
          }
          if (field.key && field.key.includes("#")) {
            field.key = field.key.split("#")[1];
          }
          if (!this.fieldFilledByCurrentUser(field.filled_by)) {
            return;
          }
          if (
            ["NUMBER"].includes(field.inputType) &&
            field.default_mapped_date_field &&
            field.default_mapped_date_type
          ) {
            return;
          }
          let checkBoxGroup =
            this.templateData?.sections[0]?.checkBoxGroup?.find(
              (e) => e.key === field.group_key
            );
          if (
            this.checkhideFields(field) ||
            field?.properties?.filed_content == "Hide"
          ) {
            return;
          }
          if (
            field.input_type === "AUTO_INCREMENT_NUMBER" &&
            this.checkIsRequired(field)
          ) {
            if (!field.generate_value_from_actions) {
              if (!data[field.key]) {
                showError = true;
                customErrorMessage = field.label + " required! Please enter.";
                this.requiredFields.push(field.label);
              } else if (
                data[field.key] &&
                field.auto_increment_prefix &&
                !data?.[field.key]
                  ?.toString()
                  .startsWith(field.auto_increment_prefix) &&
                (!this.entityDataId ||
                  data[field.key + "_existed"] !== data[field.key])
              ) {
                showError = true;
                customErrorMessage =
                  field.label +
                  " field need to be in " +
                  (field.auto_increment_prefix
                    ? field.auto_increment_prefix
                    : "") +
                  field.auto_increment_starting_number +
                  " format";
                this.requiredFields.push(field.label);
              }
            }
          } else if (field.input_type === "CAPTCHA") {
            if (!data[field.key]) {
              showError = true;
              customErrorMessage =
                "Captcha verification required! Please complete the captcha verification";
              this.requiredFields.push(field.label);
            }
          } else if (
            field.input_type == "VIDEO" &&
            this.checkIsRequired(field) &&
            this.checkIsNotFilled(field, data)
          ) {
            showError = true;
            customErrorMessage = "Please fill the " + field.label + " field";
            this.requiredFields.push(field.label);
          } else if (field.input_type == "ENTITY_TABLE") {
            let { min } = this.getEntityTableDetails(field, data);
            let l = data[field.key] ? data[field.key].length : 0;
            if (min > l) {
              customErrorMessage =
                "Please add " + (min - l) + " more rows in " + field.label;
              showError = true;
              invalidError = true;
              if (this.requiredFields.indexOf(field.label) == -1) {
                this.requiredFields.push(field.label);
              }
            }
          } else if (field.input_type == "ZIP_CODE") {
            if (data[field.key] && !reZipCode.test(data[field.key])) {
              customErrorMessage = "Invalid ZIP code";
              showError = true;
            }
          } else if (
            field.input_type == "WEEKDAYS" &&
            this.checkIsRequired(field) &&
            this.checkIsNotFilled(field, data)
          ) {
            if (field.allow_multiple) {
              customErrorMessage =
                "Please select " +
                field.min_value +
                " options for " +
                field.label +
                " field";
            }
            showError = true;
            this.requiredFields.push(field.label);
          } else if (
            field.input_type == "CHECKBOX" &&
            this.checkIsRequired(field)
          ) {
            if (!data[field.key] || data[field.key].length === 0) {
              showError = true;
              if (field?.default_error_message) {
                customErrorMessage = field.default_error_message;
              } else {
                if (!customErrorMessage.includes(field.label)) {
                  if (customErrorMessage.includes("Please fill")) {
                    customErrorMessage =
                      customErrorMessage + ", " + field.label;
                  } else {
                    customErrorMessage = "Please fill " + field.label;
                  }
                }
              }
              this.requiredFields.push(field.label);
            }
          } else if (field.input_type == "VIDEO") {
            if (this.isUploading) {
              showError = true;
            }
          } else if (field.input_type == "NUMBER") {
            data[field.key] = parseFloat(data[field.key]);
            if (!this.isNumber(data[field.key])) {
              showError = true;
              customErrorMessage =
                field.label +
                " field value is not a number.Please enter valid numbers. ";
            } else if (
              field.min_value !== null &&
              field.min_value !== undefined &&
              field.max_value !== null &&
              field.max_value !== undefined
            ) {
              if (field.min_value !== field.max_value) {
                if (
                  Number(data[field.key]) < field.min_value ||
                  Number(data[field.key]) > field.max_value
                ) {
                  showError = true;
                  customErrorMessage =
                    field.label +
                    " should range between " +
                    field.min_value +
                    " and " +
                    field.max_value;
                }
              }
            } else if (
              this.checkIsRequired(field) &&
              field.properties &&
              field.properties.number_type &&
              field.properties.number_type === "PERCENTAGE"
            ) {
              //percentage field validation
            } else if (
              this.checkIsRequired(field) &&
              this.checkIsNotFilled(field, data)
            ) {
              showError = true;
              if (!customErrorMessage.includes(field.label)) {
                if (customErrorMessage.includes("Please fill")) {
                  customErrorMessage = customErrorMessage + ", " + field.label;
                } else {
                  customErrorMessage = "Please fill " + field.label;
                }
              }
              this.requiredFields.push(field.label);
            }
          } else if (
            field.input_type === "IMAGE" &&
            field.image_url === "" &&
            field.validations.required
          ) {
            showError = true;
          } else if (
            field.input_type == "HYPER_LINK" &&
            data[field.key] &&
            !this.validateSingleLineText(data[field.key], "HYPER_LINK")
          ) {
            showError = true;
            customErrorMessage = "Invalid URL at " + field.label;
          } else if (field.input_type == "MASKED" && data[field.key]) {
            if (
              !this.validateSingleLineText(
                data[field.key + "realValue"]
                  ? data[field.key + "realValue"]
                  : data[field.key],
                field.properties.masked_type
              )
            ) {
              showError = true;
              customErrorMessage =
                `Invalid ${field.properties.masked_type} format at ` +
                field.label;
            }
          } else if (
            field.validations &&
            field.validations.required &&
            // !data[field.key] &&
            ((!this.checkIsDisabled(field) &&
              !this.checkhideFields(field) &&
              !this.hasRepeatableTemplate) ||
              (this.hasRepeatableTemplate &&
                !this.checkFieldFillStatus(field, data)))
          ) {
            if (
              field.validations.type &&
              field.validations.type == "ARRAY" &&
              field.key &&
              data[field.key] &&
              !data[field.key].length
            ) {
              showError = true;
              if (!customErrorMessage.includes(field.label)) {
                if (customErrorMessage.includes("Please fill")) {
                  customErrorMessage = customErrorMessage + ", " + field.label;
                } else {
                  customErrorMessage = "Please fill " + field.label;
                }
              }
              this.requiredFields.push(field.label);
            } else if (
              field.key &&
              (data[field.key] === null ||
                data[field.key] === undefined ||
                data[field.key] === "")
            ) {
              showError = true;
              if (!customErrorMessage.includes(field.label)) {
                if (customErrorMessage.includes("Please fill")) {
                  customErrorMessage = customErrorMessage + ", " + field.label;
                } else {
                  customErrorMessage = "Please fill " + field.label;
                }
              }
              this.requiredFields.push(field.label);
            }
          } else if (field?.inputType == "DATA_TABLE") {
            let checkedData = this.checkDataTableRequiredFields(
              data[field.key],
              field
            );
            if (!checkedData?.success) {
              customErrorMessage = checkedData.message;
              showError = true;
            }
          }
          if (field?.inputType == "ENTITY_TABLE") {
            let checkedData = this.checkDataTableRequiredFields(
              data[field.key],
              field
            );
            if (!checkedData?.success) {
              customErrorMessage = checkedData.message;
              showError = true;
            }
          }

          if (
            this.templateData &&
            this.templateData.type == "STANDARD" &&
            this.templateData.slug == "contactdetails"
          ) {
            let NameRe = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
            // let PhoneRe =
            //   /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
            let PhoneRe =
              /^\+?(\d{1,4})?[\s.-]?\(?\d{1,4}\)?[\s.-]?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/;
            if (field.key == "first_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key] ||
                (data[field.key] && !data[field.key].trim().length)
              ) {
                if (!data[field.key]) {
                  customErrorMessage = "Please fill First Name";
                } else {
                  customErrorMessage = "Invalid first name";
                }
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "last_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key] ||
                (data[field.key] && !data[field.key].trim().length)
              ) {
                if (!data[field.key]) {
                  customErrorMessage = "Please fill Last Name";
                } else {
                  customErrorMessage = "Invalid last name";
                }
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "phone_number") {
              if (
                field &&
                data[field.key] &&
                !PhoneRe.test(data[field.key].replaceAll(" ", ""))
              ) {
                customErrorMessage = "Invalid Phone Number";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "email") {
              if (
                !this.hideActions &&
                data[field.key] &&
                data[field.key].toLowerCase() ==
                  this.getAuthenticatedUser.email.toLowerCase()
              ) {
                this.$message({
                  message: "You cannot add sender email!.",
                  type: "error",
                });
                data[field.key] = "";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                this.requiredFields.push(field.label);
              } else {
                if (
                  data[field.key] &&
                  (!data[field.key].length || !reEmail.test(data[field.key]))
                ) {
                  emailFormatError = true;
                  checkboxMinMaxCheck = false;
                  multiselectMinMaxCheck = false;
                  fileMinMaxCheck = false;
                  showError = true;
                  invalidError = true;
                  this.requiredFields.push(field.label);
                }
              }
            }
          }
          if (
            field.inputType == "FILE" &&
            field.validations.multiple &&
            data[field.key] &&
            data[field.key].files &&
            data[field.key].files.length &&
            !this.checkInBetween(
              data[field.key].files.length,
              field.validations.min_document_files,
              field.validations.max_document_files
            )
          ) {
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = true;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.fileMinValue = field.validations.min_document_files;
            this.fileMaxValue = field.validations.max_document_files;
          }
          if (field.inputType === "CHECKBOX" && checkBoxGroup?.min_value > 0) {
            if (
              this.checkCheckBoxMinValue(field, data) === 0 ||
              this.checkCheckBoxMinValue(field, data) < checkBoxGroup.min_value
            ) {
              customErrorMessage = field.default_error_message;
              checkboxMinMaxCheck = true;
              fileMinMaxCheck = false;
              showError = true;
              invalidError = true;
              this.requiredFields.push(field.label);
              this.minValue = checkBoxGroup.min_value;
            }
          }
          if (
            field.inputType == "CHECKBOX_GROUP" &&
            data[field.key] &&
            data[field.key]?.length < this.getMinValue(field, data)
          ) {
            customErrorMessage = field.default_error_message;
            checkBoxLabel = field.label;
            checkboxMinMaxCheck = true;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
          if (
            field.inputType == "CHECKBOX_GROUP" &&
            field.validations.required &&
            (!data[field.key] || !data[field.key].length) &&
            !field.apply_default_at_end
            ){
            customErrorMessage = field.default_error_message;
            checkBoxLabel = field.label;
            checkboxMinMaxCheck = true;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
          if (
            field.inputType === "MULTI_SELECT" &&
            data[field.key] &&
            data[field.key].length &&
            this.getMinValue(field, data) > 0 &&
            data[field.key].length < this.getMinValue(field, data)
          ) {
            customErrorMessage = field.default_error_message;
            multiselectLabel = field.label;
            multiselectMinMaxCheck = true;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
          if (
            field.inputType === "MULTI_SELECT" &&
            this.getMinValue(field, data) > 0 &&
            (!data[field.key] || !data[field.key].length)
          ) {
            customErrorMessage = field.default_error_message;
            multiselectLabel = field.label;
            multiselectMinMaxCheck = true;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType === "MULTI_SELECT" &&
            (this.getMinValue(field, data) === 0 ||
              this.getMinValue(field, data) === null) &&
            this.getMaxValue(field, data) === null &&
            (!data[field.key] || !data[field.key].length)
          ) {
            customErrorMessage = "";
            multiselectLabel = "";
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = false;
            invalidError = false;
          }
          if (
            !this.checkhideFields(field) &&
            ((this.checkIsRequired(field) &&
              field.input_type == "SINGLE_LINE_TEXT") ||
              (this.checkIsRequired(field) &&
                field.input_type == "MULTI_LINE_TEXT")) &&
            (!data[field.key] || !data[field.key].trim().length)
          ) {
            this.requiredFields.push(field.label);
            showError = true;
          }
        });
      });
      let savingStopFormRules = [
        ...this.templateRules,
        ...this.alertRules,
      ].filter((rule) => {
        if (rule.actions) {
          return rule.actions.find((e) => e.restrict_save) ? true : false;
        }
      });
      if (savingStopFormRules && savingStopFormRules.length) {
        savingStopFormRules.forEach((rule) => {
          let checkRule = this.checkSingleRule(
            rule,
            this.form,
            this.fieldsData
          );
          if (checkRule) {
            rule.actions.forEach((action) => {
              if (
                action.selectField == "NOTIFICATION" &&
                action.restrict_save
              ) {
                (customErrorMessage = action.message + " " + action.value),
                  (showError = true);
              }
            });
          }
        });
      }
      let templatePrimaryFields = this.currentEntity.primaryFields.filter(
        (e) =>
          e.template_id &&
          e.template_id.toString() == this.templateData._id.toString() &&
          this.fieldsData.map((fd) => fd.key).includes(e.key)
      );
      if (templatePrimaryFields && templatePrimaryFields.length) {
        for (let primary of templatePrimaryFields) {
          const fieldValue = this.form?.[primary?.key];
          if (typeof fieldValue === "string") {
            console.log(
              `Length of trimmed field value: ${fieldValue.trim().length}`
            );
          }
          let isInvalid = false;
          if (fieldValue === undefined || fieldValue === null) {
            isInvalid = true;
          } else if (
            typeof fieldValue === "string" &&
            !fieldValue.trim().length
          ) {
            isInvalid = true;
          } else if (typeof fieldValue === "number" && isNaN(fieldValue)) {
            isInvalid = true;
          }
          if (isInvalid) {
            showError = true;
            if (!customErrorMessage.includes(primary.label)) {
              if (customErrorMessage.includes("Please fill")) {
                customErrorMessage = customErrorMessage + ", " + primary.label;
              } else {
                customErrorMessage = "Please fill " + primary.label;
              }
            }

            this.requiredFields.push(primary.label);
          }
        }
      }

      return {
        showError,
        invalidError,
        emailFormatError,
        checkboxMinMaxCheck,
        customErrorMessage,
        checkBoxLabel,
        fileMinMaxCheck,
        multiselectLabel,
        multiselectMinMaxCheck,
        timerangeLabel,
        yesornoLabel,
      };
    },
    handleUploadStarted() {
      this.isUploading = true;
    },
    handleUploadFinished() {
      this.isUploading = false;
    },
    checkDataTableRequiredFields(data, field) {
      let message = "Please fill required fields in " + field.label;
      let success = true,
        fieldRules = (field.rules || []).filter((rule) => {
          if (rule.actions) {
            return rule.actions.find(
              (c) => c.selectField == "NOTIFICATION" && c.restrict_save
            )
              ? true
              : false;
          }
        });
      (data || []).forEach((form, i) => {
        if (field.data_table_columns) {
          field.data_table_columns.forEach((fl) => {
            const combinedKey = `${field.key}#${fl.key}`;
            const isHidden =
              this.hideFields.some((hideKey) => hideKey === combinedKey) ||
              (this.tableHiddenColumns?.[field.key] || []).includes(
                fl.key + "$" + i
              );
            if (isHidden) {
              return;
            }

            if (
              fl.validations.required &&
              (!fl?.properties?.filed_content ||
                fl?.properties?.filed_content == "")
            ) {
              let filled = this.checkFieldFilled(fl, form);
              if (!filled && !this.checkIsSelfRelatedEntity(field, fl)) {
                success = false;
                message =
                  "Please fill " +
                  fl.label +
                  " in " +
                  field.label +
                  " " +
                  (i + 1) +
                  "  row";
              }
            }
            if (
              fl.inputType == "FILE" &&
              fl.validations.multiple &&
              form[fl.key] &&
              form[fl.key].files &&
              form[fl.key].files.length &&
              !this.checkInBetween(
                form[fl.key].files.length,
                fl.validations.min_document_files,
                fl.validations.max_document_files
              )
            ) {
              this.fileMinValue = fl.validations.min_document_files;
              this.fileMaxValue = fl.validations.max_document_files;
              success = false;
              message =
                `Please upload minimum '${this.fileMinValue}' files or maximum '${this.fileMaxValue}' files , to meet the minimum & maximum file(s) requirement.` +
                "  row";
            }
          });
        }
        if (fieldRules && fieldRules.length) {
          fieldRules.forEach((rule) => {
            let results = [];
            rule.conditions.forEach((condition) => {
              results.push(
                this.checkCondition(condition, form, field.data_table_columns)
              );
            });
            if (rule.query_type == "AND" && results.every((el) => el)) {
              message = "";
              let notify = rule.actions.find(
                (c) => c.selectField == "NOTIFICATION" && c.restrict_save
              );
              message = notify.message + " on " + (i + 1) + "  row";
              success = false;
            } else if (rule.query_type == "OR" && results.some((el) => el)) {
              message = "";
              let notify = rule.actions.find(
                (c) => c.selectField == "NOTIFICATION" && c.restrict_save
              );
              message = notify.message + " on " + (i + 1) + " row";
              success = false;
            }
          });
        }
      });
      return {
        message,
        success,
      };
    },
    checkIsSelfRelatedEntity(tableField, currentField) {
      return (
        tableField.data_table_field_auto_fill &&
        tableField.selected_auto_fill_entity == tableField.parent_entityId &&
        currentField.inputType == "ENTITY" &&
        currentField.entity_id == tableField.parent_entityId
      );
    },
    checkFieldFilled(field, data) {
      let showError = false;
      //read only entity variable
      if (
        field?.field_assignable == "read_only" &&
        field?.input_type == "ENTITY_VARIABLE"
      ) {
        return !showError;
      }
      if (field.inputType == "NUMBER") {
        data[field.key] = parseFloat(data[field.key]);
        if (!this.isNumber(data[field.key])) {
          showError = true;
        } else if (
          this.checkIsRequired(field) &&
          field.properties &&
          field.properties.number_type &&
          field.properties.number_type === "PERCENTAGE"
        ) {
          //percentage field validation
        } else if (
          this.checkIsRequired(field) &&
          this.checkIsNotFilled(field, data)
        ) {
          showError = true;
        }
      } else {
        if (
          (!data[field.key] || !data[field.key].length) &&
          !this.isNumber(data[field.key])
        ) {
          showError = true;
        }
      }
      return !showError;
    },
    checkCheckBoxMinValue(fields, data) {
      let groupSelectedFields = [];
      if (this.fieldsData && this.fieldsData.length) {
        groupSelectedFields = this.fieldsData.filter(
          (field) =>
            field.group_key &&
            field.group_key == fields.group_key &&
            data[field.key]
        );
      }
      return groupSelectedFields.length;
    },
    getMinValue(field, data) {
      if (field && data[field.key + "/min_value"]) {
        return data[field.key + "/min_value"];
      }
      return field.min_selection || undefined;
    },
    checkRepeatableIsDisabled(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.disabledFields &&
        this.templateRuleFields.disabledFields[indexKey]
      ) {
        return this.templateRuleFields.disabledFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkRepeatableFields(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.hideFields &&
        this.templateRuleFields.hideFields[indexKey]
      ) {
        return this.templateRuleFields.hideFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkFieldFillStatus(field, data) {
      return (
        this.checkRepeatableIsDisabled(field, data.indexKey) ||
        this.checkRepeatableFields(field, data.indexKey)
      );
    },
    checkInBetween(fieldCount, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldCount.between(minValue, maxValue);
    },
    async saveTemplate(openNew, fromActionButton = false) {
      this.showAlertFields = false;
      let fieldRules = [];
  fieldRules = (this.templateRules || []).filter((rule) => {
    if (rule.actions) {
      return rule.actions.find(
        (c) => c.input_type == "CHECKBOX_GROUP" && c.operator_type == 'Make_this_field_unrequired'
      )
        ? true
        : false;
    }
  });

  let skipValidation = false; 
  if (fieldRules && fieldRules.length) {
    fieldRules.forEach((rule) => {
      let results = [];
      rule.conditions.forEach((condition) => {
        const result = this.checkCondition(condition, this.form);
        results.push(result);
      });
      if (
        (rule.query_type === "AND" && results.every((el) => el)) ||
        (rule.query_type === "OR" && results.some((el) => el))
      ) {
        skipValidation = true;
      }
    });
  }
  if (skipValidation) {
    this.applyRulesAndSaveData(openNew, fromActionButton); 
    return;
  }
      if (this.entityDataExists) {
        if (this.printEntityData || this.downloadEntityData) {
          await this.printEntityDataOnDocument();
          return;
        }
      }
      let hasData = true;
      let requiredFields = this.fieldsData.filter(
        (e) =>
          e &&
          e.validations &&
          e.validations.required &&
          !this.checkhideFields(e) &&
          e?.properties?.filed_content != "Hide"
      );
      if (requiredFields && requiredFields.length == 0) {
        hasData = true;
      } else {
        for (let reqField of requiredFields) {
          if (!this.form[reqField.key] && reqField.input_type !== "NUMBER") {
            hasData = false;
            break;
          }
          if (
            reqField.input_type == "NUMBER" &&
            this.form[reqField.key] &&
            this.form[reqField.key].toString() == ""
          ) {
            hasData = false;
            break;
          }
        }
      }
      if (!hasData && !this.hasRepeatableTemplate) {
        if (requiredFields && requiredFields.length) {
          this.checkRequiredFields();
        } else {
          this.$notify.error({
            title: "Error",
            message: "Please fill all required fields.",
          });
          return;
        }
      }
      let checkData = this.checkAndShowAlerts();
        if (checkData) {
          if (this.alertRules.length && this.alertRules[0]) {
            let rule = this.alertRules.find((e) => e.show_alert);
            if (this.alertFields.length) {
              let checkRule = this.checkSingleRule(
                rule,
                this.form,
                this.fieldsData
              );
              if (!checkRule) {
                this.applyRulesAndSaveData(openNew, fromActionButton);
                return;
              }
              this.alertFormFields = [];
              if (this.templateData?.sections[0]?.fields) {
                let allFields = [];
                this.templateData.sections[0].fields.forEach((el) => {
                  let e = JSON.parse(JSON.stringify(el));
                  if (e.key && e.key.includes("#")) {
                    e.key = e.key.split("#")[1];
                  }
                  allFields.push(e);
                });
                this.alertFormFields = allFields.filter(
                  (e) => this.alertFields.indexOf(e.key) != -1
                );
                this.alertFormFields = this.alertFormFields.map((e) => {
                  if (e.key && e.key.includes("#")) {
                    e.key = e.key.split("#")[1];
                  }
                  return e;
                });
                this.isSaveAndNew = openNew;
                this.showAlertFields = true;
              }
            } else if (!rule?.show_alert) {
              this.applyRulesAndSaveData(openNew, fromActionButton);
            } else {
              this.alertMessage(
                rule.alert_message,
                "Yes",
                "No",
                openNew,
                fromActionButton
              );
            }
          } else {
            let newForm = this.mapDefaultValues(
              this.fieldsData,
              JSON.parse(JSON.stringify(this.form)),
              true
            );
            Object.keys(newForm).forEach((key) => {
              this.$set(this.form, key, newForm[key]);
            });
            let newForm1 = await this.getAIFieldsResponse(
              this.fieldsData,
              {},
              JSON.parse(JSON.stringify(this.form)),
              "Updated"
            );
            Object.keys(newForm1).forEach((key) => {
              this.$set(this.form, key, newForm1[key]);
            });
            this.checkForDuplicateData(openNew, fromActionButton);
          }
          this.$emit("templateUpdated", true);
        }
    },
    handleResize() {
      // this.setTemplateInfo(this.templateData);
    },
    setDefultValuesForFields() {
      let newForm = this.mapDefaultValues(
        this.fieldsData,
        JSON.parse(JSON.stringify(this.form)),
        false
      );
      Object.keys(newForm).forEach((key) => {
        this.$set(this.form, key, newForm[key]);
      });
    },
    checkAndShowAlerts() {
      if (this.isUploading) {
        this.$message.warning("Video is uploading, please wait");
        return false; // Exit early if the video is uploading
      }
      let checkData = this.checkRequiredFields();
      if (checkData.showError) {
        if (checkData.customErrorMessage) {
          this.$notify.error({
            title: "Error",
            message: checkData.customErrorMessage,
          });
        } else if (checkData.emailFormatError) {
          this.$notify.error({
            title: "Error",
            message: "There is an error in the email format. Please check",
          });
        } else if (checkData.checkboxMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please select at least '<span style="color: red;"> ${this.minValue || 1}</span>' option(s) for '<span style="color: red;font-weight:bold">${checkData.checkBoxLabel}</span>' Checkbox group to meet the minimum selection requirement.`,
            dangerouslyUseHTMLString: true,
          });
        } else if (checkData.multiselectMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please select at least '<span style="color: red;"> ${this.minValue}</span>' option(s) for '<span style="color: red;font-weight:bold">${checkData.multiselectLabel}</span>' Multi Select to meet the minimum selection requirement.`,
            dangerouslyUseHTMLString: true,
          });
        } else if (checkData.fileMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please upload minimum '${this.fileMinValue}' files or maximum '${this.fileMaxValue}' files , to meet the minimum & maximum file(s) requirement.`,
          });
        } else if (this.isUploading) {
          this.$message.warning("Video is uploading, please wait");
        }
        //  else {
        //   this.$notify.error({
        //     title: "Error",
        //     message: "Please fill all required fields",
        //   });
        // }
        return false;
      } else if (
        this.hasRepeatableTemplate &&
        this.entitiesData.length < this.min_value
      ) {
        let name =
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.templateInfo &&
          this.entityRepeatableTemplateData.templateInfo.name
            ? this.entityRepeatableTemplateData.templateInfo.name
            : "template";
        this.$notify.error({
          title: "Error",
          message:
            "Please add " +
            name +
            " data " +
            (this.entitiesData.length > 0
              ? (this.min_value - this.entitiesData.length || 1) + " more times"
              : ""),
        });
        return false;
      } else {
        return true;
      }
    },
    async addNewEntityData(
      openNew,
      fromActionButton = false,
      isFromPaymentButton = false,
      paymentInfo = {}
    ) {
      try {
        this.loading = true;
        this.loadingText = "Adding new data...";
        if (this.isSaveAsDraft) {
          let hasData = (Object.values(this.form) || []).filter(
            (e) => !["", null, undefined].includes(e)
          );
          if (!hasData.length && !this.hasRepeatableTemplate) {
            this.loading = false;
            return this.$notify.error({
              title: "Error",
              message: `Please fill atleat one field`,
            });
          }
          let data = {
            template_id:
              this.saveAndSwitch && this.templateIdWhileSwitchingTabs
                ? this.templateIdWhileSwitchingTabs
                : this.templateData._id,
            template_data: this.form,
            user_id: this.getNewFormbuilderData,
            entity_id: this.getEntityId,
            template_completion_status:
              this.getRquiredFieldsCount === this.getTotalFieldsCount,
            entity_data_id: this.entityDataId ? this.entityDataId : null,
            isApprovalForm: this.isApprovalForm || this.fromScheduling || false,
            is_created_from_scheduling: this.fromScheduling
              ? this.fromScheduling
              : false,
            is_save_and_draft: this.isSaveAsDraft,
            other_template_form: this.otherEntityForm || {},
            isApplyRelationShips: this.fromAddWidget,
          };
          if (this.profilePicture) {
            data.profilePicture = this.profilePicture;
          }
          if (this.existedTemplateDataId) {
            data.template_data_id = this.existedTemplateDataId;
          }
          await this.$store.dispatch(
            "templatesData/createEntityDataByTemplateData",
            data
          );
          if (this.isApplicationUserSide) {
            let menu_id = this.$route?.query?.menu_id;
            this.$router.push({
              path: `/ap/entity/${this.currentEntity._id}/${menu_id}`,
              query: tempQuery,
            });
            this.loading = false;
            return;
          }
          let tempQuery = {};
          if (this.$route?.query?.filter) {
            tempQuery["filter"] = this.$route.query.filter;
          }
          if (this.$route?.query?.routeType) {
            tempQuery["routeType"] = this.$route.query.routeType;
          }
          if (this.$route?.query?.viewType) {
            tempQuery["viewType"] = this.$route.query.viewType;
          }
          let UrlPath = this.getIframeUrlInfo
            ? `/if/${this.getIframeUrlInfo._id}/entity/${this.currentEntity._id}`
            : `/entity/${this.currentEntity._id}`;
          this.$router.push({
            path: UrlPath,
            query: tempQuery,
          });
          this.loading = false;
          return;
        }
        let primaryCheck = await (this.currentEntity.primaryFields || []).find(
          (field) => field.is_primary
        );
        if (primaryCheck && primaryCheck.template_id != this.templateData._id) {
          this.loading = false;
          return this.$notify.error({
            title: "Error",
            message: `Please Add ${primaryCheck.label} first`,
          });
        }
        let hasData = (Object.values(this.form) || []).filter(
          (e) => !["", null, undefined].includes(e)
        );
        if (!hasData.length && !this.hasRepeatableTemplate) {
          this.loading = false;
          return this.$notify.error({
            title: "Error",
            message: `Please fill atleat one field`,
          });
        }
        this.loadingText = "Adding new data...";
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          form[key] = this.entitiesData.map((e) => {
            if (e.isNewData) {
              delete e.isNewData;
            }
            e = this.mapDefaultValues(this.fieldsData, e, true);
            return e;
          });
        } else {
          //  form = this.form;
          let incrementFields = this.fieldsData.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }
          // this.form = this.mapDateValues(this.fieldsData, this.form);
          form = await this.prepareFormData();
          let newForm1 = await this.getAIFieldsResponse(
            this.fieldsData,
            {},
            JSON.parse(JSON.stringify(this.form)),
            "Added"
          );
          Object.keys(newForm1).forEach((key) => {
            this.$set(this.form, key, newForm1[key]);
          });
          //Update parent entity data by entity variable - mixin method
          if (!this.isApprovalForm) {
            await this.updateParentDataByEntityVariable(
              this.fieldsData,
              this.form
            );
          }
          if (
            form &&
            form.first_name &&
            form.last_name &&
            form.first_name.length &&
            form.last_name.length &&
            !form.name
          ) {
            form.name = form.first_name + " " + form.last_name;
          }
        }
        let data = {
          template_id:
            this.saveAndSwitch && this.templateIdWhileSwitchingTabs
              ? this.templateIdWhileSwitchingTabs
              : this.templateData._id,
          template_data: form,
          user_id: this.getNewFormbuilderData,
          entity_id: this.getEntityId,
          template_completion_status:
            this.getRquiredFieldsCount === this.getTotalFieldsCount,
          entity_data_id: this.entityDataId ? this.entityDataId : null,
          isApprovalForm: this.isApprovalForm || this.fromScheduling || false,
          is_created_from_scheduling: this.fromScheduling
            ? this.fromScheduling
            : false,
          other_template_form: this.otherEntityForm || {},
          isApplyRelationShips: this.fromAddWidget,
        };
        if (this.profilePicture) {
          data.profilePicture = this.profilePicture;
        }
        if (this.existedTemplateDataId) {
          data.template_data_id = this.existedTemplateDataId;
        }
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          data
        );

        if (this.getEntityDataCreateByTemplateStatus) {
          if (this.templateData?.show_success_notification_after_save) {
            this.$notify.success({
              title: "Success",
              message: "Data saved successfully",
            });
          }
          if (this.fromAddWidget) {
            this.$emit("saveAndAssignRelation", this.getNewEntityData?._id);
            this.loading = false;
            return;
          }
          if (this.getNewEntityData?._id) {
            if (isFromPaymentButton && paymentInfo && paymentInfo.action) {
              // Emit To pay button
              this.$bus.$emit(`initPayButton_${paymentInfo.busKey}`, {
                ...paymentInfo,
                location: window.location.href,
                dataId: this.getNewEntityData?._id,
                template_id: this.templateData._id,
                entity_id: this.currentEntity._id,
                actionType: "NEW_RECORD",
              });
              this.loading = false;
              this.loadingText = "Loading...";
            } else {
              // Need to refactor this block of code  *** updateEntitiesData methods calls so many times here ***
              if (this.fromScheduling && !fromActionButton) {
                this.$emit("entity-data-created", {
                  dataId: this.getNewEntityData?._id,
                });
                await this.updateEntitiesData(this.getNewEntityData?._id);
                return;
              }
              let type;
              if (this.isApplicationUserSide) {
                type = this.$route.query.type;
              }
              if (this.hideActions) {
                await Promise.all([
                  this.updateEntitiesData(this.getNewEntityData?._id, true),
                ]);
                if (this.currentStep?.has_next) {
                  if (fromActionButton) {
                    this.$bus.$emit(
                      "execute-action",
                      this.getNewEntityData?._id
                    );
                    return;
                  }
                  this.$emit("entity-data-created", {
                    dataId: this.getNewEntityData?._id,
                  });
                  this.gotoNextTemplate();
                } else {
                  if (fromActionButton) {
                    this.$bus.$emit(
                      "execute-action",
                      this.getNewEntityData?._id
                    );
                    return;
                  }
                  this.$emit("entity-data-created", {
                    dataId: this.getNewEntityData?._id,
                    moveToNext: true,
                  });
                }
                return;
              }
              this.checkAndSetRelationship(this.getNewEntityData?._id);
              let query = this.getNavigationQuery(this.$route.query);
              query["dataId"] = this.getNewEntityData._id;
              if (this.isApplicationUserSide) {
                query["type"] = type;
              }
              await this.updateEntitiesData(this.getNewEntityData?._id, false);
              if (this.currentStep?.has_next) {
                // query["stepId"] = 1;
                this.$router.push({
                  name: this.$route.name,
                  query: query,
                });
                this.$bus.$emit("fetch-entity-data");
                await this.updateEntitiesData(
                  this.getNewEntityData?._id,
                  false
                );
                if (fromActionButton) {
                  this.$bus.$emit("execute-action", this.getNewEntityData?._id);
                  this.loading = false;
                  return;
                }
                this.gotoNextTemplate();
              } else {
                if (fromActionButton) {
                  this.$router.push({
                    name: this.$route.name,
                    query: query,
                  });
                  this.$bus.$emit("fetch-entity-data");
                  await this.updateEntitiesData(
                    this.getNewEntityData?._id,
                    false
                  );
                  this.$bus.$emit("execute-action", this.getNewEntityData?._id);
                  this.loading = false;
                  return;
                }
                this.$bus.$emit("fetch-entity-data");
                await this.updateEntitiesData(
                  this.getNewEntityData?._id,
                  false
                );
                if (this.fromAddWidget) {
                  this.$emit(
                    "saveAndAssignRelation",
                    this.getNewEntityData?._id
                  );
                  this.loading = false;
                  return;
                }
                let tempQuery = {};
                if (this.$route?.query?.filter) {
                  tempQuery["filter"] = this.$route.query.filter;
                }
                if (this.$route?.query?.routeType) {
                  tempQuery["routeType"] = this.$route.query.routeType;
                }
                if (this.$route?.query?.viewType) {
                  tempQuery["viewType"] = this.$route.query.viewType;
                }
                if (openNew) {
                  let query = this.getNavigationQuery(this.$route.query);
                  query["dataId"] = "";
                  query["stepId"] = 0;
                  if (this.$route?.query?.type) {
                    query["type"] = this.$route.query.type;
                  }
                  this.$router.push({
                    name: this.$route.name,
                    query: query,
                  });
                  this.refreshThePage(false);
                  this.form = {};
                  this.loading = false;
                  return;
                }
                if (this.printEntityData || this.downloadEntityData) {
                  await this.printEntityDataOnDocument(
                    this.getNewEntityData?._id
                  );
                }
                if (this.$route.query?.fromEntityView) {
                  this.$router.go(-1);
                  return;
                }
                if (this.isApplicationUserSide) {
                  let menu_id = this.$route?.query?.menu_id;
                  if (
                    this.customization &&
                    this.customization.tab_groups &&
                    this.customization.tab_groups.length
                  ) {
                    if (!this.isSplitView) {
                      this.$emit("goToNextTab", this.getNewEntityData._id);
                    }
                    this.loading = false;
                  } else {
                    let menuItem = null;
                    if (this.$route?.query?.redirect) {
                      menuItem = this.getMenuItem(this.$route.query.redirect);
                    }
                    if (menuItem != null) {
                      this.goToMenuItem(menuItem, menuItem.type === "Child");
                    } else if (this.$route?.query?.addNew == "true") {
                      this.$router.push({ path: "/ap/dashboard" });
                    } else {
                      this.$router.push({
                        path: `/ap/entity/${this.currentEntity._id}/${menu_id}`,
                        query: tempQuery,
                      });
                    }
                  }
                } else {
                  if (
                    this.customization &&
                    this.customization.tab_groups &&
                    this.customization.tab_groups.length
                  ) {
                    if (!this.isSplitView) {
                      this.$emit("goToNextTab", this.getNewEntityData._id);
                      if (this.isFromScheduleAction) {
                        this.$emit("schedule-event-called", {
                          scheduleEvent: true,
                        });
                      }
                    }
                    this.loading = false;
                  } else {
                    if (this.isFromScheduleAction) {
                      this.$eventBus.$emit("showCalendarEvent", {
                        source: "entity",
                        entityId: this.currentEntity._id,
                        entityDataId: this.getNewEntityData?._id
                          ? this.getNewEntityData._id
                          : this.entityDataId,
                      });
                      //return;
                    }
                    let menuItem = null;
                    if (this.$route?.query?.redirect) {
                      menuItem = this.getMenuItem(this.$route.query.redirect);
                    }
                    if (menuItem != null) {
                      this.goToMenuItem(menuItem, menuItem.type === "Child");
                    } else if (this.$route?.query?.addNew == "true") {
                      this.$router.push({ path: "/dashboard" });
                    } else {
                      let UrlPath = this.getIframeUrlInfo
                        ? `/if/${this.getIframeUrlInfo._id}/entity/${this.currentEntity._id}`
                        : `/entity/${this.currentEntity._id}`;
                      this.$router.push({
                        path: UrlPath,
                        query: tempQuery,
                      });
                    }
                  }
                }
              }
            }
          }
        } else {
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
          this.loading = false;
        }
      } catch (err) {
        console.log("addNewEntityData", err);
      }
    },
    async checkAndSetRelationship(entityDataId) {
      if (
        this.$route.query.menu_id &&
        this.getEntityMenu &&
        this.getEntityMenu.menu_list
      ) {
        let currentMenu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            currentMenu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                currentMenu = el;
              }
            });
          }
        });
        if (currentMenu?.relational_entity == this.$route.params.entity_id) {
          let relationshipsData = [...(this.getEntityRelationships || [])];

          relationshipsData = relationshipsData.map((e) => {
            if (e.parent_entity_id == this.$route.params.entity_id) {
              e.owner_type = "PARENT";
              e.relationship_title = e.child_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.child_entity_id;
            } else {
              e.owner_type = "CHILD";
              e.relationship_title = e.parent_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.parent_entity_id;
            }
            e.parent_entity_data_id = entityDataId
              ? entityDataId
              : this.entityDataId;
            return e;
          });

          relationshipsData = relationshipsData.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
          let currentActiveWorkspace =
            this.getAuthenticatedUser.contact_types.find((e) => {
              let id =
                e.contact_type && e.contact_type._id
                  ? e.contact_type._id
                  : e.contact_type;
              if (id && id == this.getActiveContactType?.contact_type?._id) {
                return true;
              }
            });
          if (
            currentActiveWorkspace &&
            currentActiveWorkspace.contact_type &&
            currentActiveWorkspace.account_data_id
          ) {
            let currentRelationship = relationshipsData.find((e) => {
              let id = currentActiveWorkspace.contact_type._id
                ? currentActiveWorkspace.contact_type._id
                : currentActiveWorkspace.contact_type;
              if (id) {
                if (
                  (id == e.child_entity_id &&
                    e.parent_entity_id == this.$route.params.entity_id) ||
                  (this.$route.params.entity_id == e.child_entity_id &&
                    e.parent_entity_id == id)
                ) {
                  return true;
                }
              }
            });
            if (currentRelationship) {
              if (currentRelationship.representation == "TAB") {
                let data;
                if (currentRelationship.owner_type == "PARENT") {
                  data = {
                    parent_entity_id: currentRelationship.parent_entity_id,
                    child_entity_id: currentRelationship.child_entity_id,
                    parent_entity_data_id:
                      currentRelationship.parent_entity_data_id
                        ? currentRelationship.parent_entity_data_id
                        : entityDataId,
                    child_entity_data_ids: [
                      currentActiveWorkspace.account_data_id,
                    ],
                  };
                } else {
                  if (currentRelationship.parent_relation_type == "MANY") {
                    data = {
                      parent_entity_id: currentRelationship.child_entity_id,
                      child_entity_id: currentRelationship.parent_entity_id,
                      parent_entity_data_id:
                        currentRelationship.parent_entity_data_id
                          ? currentRelationship.parent_entity_data_id
                          : entityDataId,
                      child_entity_data_ids: [
                        currentActiveWorkspace.account_data_id,
                      ],
                    };
                  } else {
                    data = {
                      parent_entity_id: currentRelationship.parent_entity_id,
                      child_entity_id: currentRelationship.child_entity_id,
                      parent_entity_data_id:
                        currentActiveWorkspace.account_data_id,
                      child_entity_data_ids: [
                        currentRelationship.parent_entity_data_id
                          ? currentRelationship.parent_entity_data_id
                          : entityDataId,
                      ],
                    };
                  }
                }
                await this.$store.dispatch(
                  "entityRelationships/assignEntityRelationshipData",
                  data
                );
              }
              // else {
              //   let allFields = this.getAllFieldsFormEntity(this.currentEntity);
              //   let toAssignRelationshipsData = this.prepareRelationshipData(
              //     allFields,
              //     relationshipsData,
              //     entityDataId ? entityDataId : this.entityDataId,
              //     currentActiveWorkspace &&
              //       currentActiveWorkspace.account_data_id
              //       ? currentActiveWorkspace.account_data_id
              //       : null
              //   );
              //   if (toAssignRelationshipsData.length) {
              //     await this.$store.dispatch(
              //       "entityRelationships/assignFieldsEntityRelationshipData",
              //       toAssignRelationshipsData
              //     );
              //   }
              // }
            }
          }
        }
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    getTemplateFields(templateData) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(templateData));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async updateTemplateData(isFromPaymentButton = false, paymentInfo = {}) {
      try {
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          const promises = await this.entitiesData.map(async (e) => {
            //Update parent entity data by entity variable - mixin method
            if (!this.isApprovalForm) {
              await this.updateParentDataByEntityVariable(this.fieldsData, e);
            }
            e = this.mapDefaultValues(this.fieldsData, e, true);
            // this.form = this.mapDateValues(this.fieldsData, e);
            for (let [key, value] of Object.entries(e)) {
              //Update parent entity data by entity variable - mixin method
              if (!this.isApprovalForm) {
                await this.updateParentDataByEntityVariable(this.fieldsData, e);
              }
              // this.form = this.mapDateValues(this.fieldsData, e);
              if (
                value &&
                typeof value == "object" &&
                value.type == "DOCUMENT" &&
                value.new == true &&
                value.file
              ) {
                this.loadingText = "Uploading files...";
                let path = await this.updatedAndUploadDocument(value.file);
                this.$set(e[key], "path", path);
                this.$set(e[key], "new", false);
                //  this.form[key].path = path;
                //  this.form[key].new = false;
              }
            }
            if (e.isNewData) {
              delete e.isNewData;
            }

            return e;
          });
          form[key] = await Promise.all(promises);
        } else {
          let incrementFields = this.fieldsData.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }
          // this.form = this.mapDateValues(this.fieldsData, this.form);
          form = await this.prepareFormData();
          //Update parent entity data by entity variable - mixin method
          if (!this.isApprovalForm) {
            await this.updateParentDataByEntityVariable(
              this.fieldsData,
              this.form
            );
          }
          if (
            this.form &&
            this.form.first_name &&
            this.form.last_name &&
            this.form.first_name.length &&
            this.form.last_name.length &&
            !this.form.name
          ) {
            this.form.name = this.form.first_name + " " + this.form.last_name;
          }
        }
        let params = {
          template_data: form,
          entity_data_id: this.entityDataId,
          template_id:
            this.saveAndSwitch && this.templateIdWhileSwitchingTabs
              ? this.templateIdWhileSwitchingTabs
              : this.templateData._id,
          entity_id: this.getEntityId,
          ...(this.hideActions && {
            company_id: this.currentEntity?.company_id,
            user_id: this.currentEntity?.created_by,
          }),
          isApprovalForm: this.isApprovalForm || false,
          isSaveAsDraft: false,
          apply_actions:
            this.$route.query.from_actions == "true" &&
            this.currentFormbuilderData?.formbuilder_data_status == "PENDING" &&
            this.isFirstTemplate,
        };
        if (this.profilePicture) {
          params.profilePicture = this.profilePicture;
        }
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
        this.loading = false;
        if (this.getTemplatesDataUpdateStatus) {
          if (this.templateData?.show_success_notification_after_save) {
            this.$notify.success({
              title: "Success",
              message: "Data updated successfully",
            });
          }
        // this.isSplitView property is coming up as undefined. 
        // Since there was an && condition, I replaced it with ||. 
        // However, due to this change, the previous data is being displayed when adding new data, 
        // and the router is still showing the old dataId.          
        if (this.isSplitView || !this.currentStep?.has_next) {
            this.backToEntityInfoList();
          }

          if (isFromPaymentButton) {
            if (paymentInfo && paymentInfo.action) {
              // // Update the Info to payment button
              this.$bus.$emit(`initPayButton_${paymentInfo.busKey}`, {
                ...paymentInfo,
                location: window.location.href,
                dataId: this.entityDataId,
                template_id: this.templateData._id,
                entity_id: this.currentEntity._id,
                actionType: "UPDATED_RECORD",
              });
            }
          } else {
            if (this.fromScheduling) {
              this.$emit("entity-data-updated");
              await this.updateEntitiesData(this.entityDataId);
              return;
            }
            if (this.fromAddWidget) {
              this.$emit("saveAndCloseEditDialog");
              this.loading = false;
              return;
            }
            if (this.hideActions) {
              await this.updateEntitiesData(this.entityDataId, true);
              //this.$emit("update-relationship", this.entityDataId);
              if (this.currentStep?.has_next) {
                this.$emit("entity-data-created", {
                  dataId: this.entityDataId,
                });
                this.gotoNextTemplate();
              } else {
                this.$emit("entity-data-created", {
                  dataId: this.entityDataId,
                  moveToNext: true,
                });
              }
              return;
            }
            if (this.printEntityData || this.downloadEntityData) {
              await this.printEntityDataOnDocument();
            } else {
              if (this.isFromScheduleAction) {
                this.$eventBus.$emit("showCalendarEvent", {
                  source: "entity",
                  entityId: this.currentEntity._id,
                  entityDataId: this.getNewEntityData?._id
                    ? this.getNewEntityData._id
                    : this.entityDataId,
                });
                //return;
              }
              this.$bus.$emit("fetch-entity-data");
              await this.updateEntitiesData(this.entityDataId);
              // if(this.isSplitView){
              //   console.log('emitting.....')
              //   this.$emit("saveAndCloseEditDialog");
              //   }
              if (
                this.customization &&
                this.customization.tab_groups &&
                this.customization.tab_groups.length
              ) {
                if (!this.isSplitView) {
                  this.$emit("goToNextTab", this.entityDataId);
                }
              } else {
                this.gotoNextTemplate();
              }
            }
          }
        } else {
          let msg = "Error while updating";
          if (this.getUserTemplateDataUpdateErrors?.message) {
            msg = this.getUserTemplateDataUpdateErrors.message;
          }
          this.$notify.error({
            title: "Error",
            message: msg,
          });
        }
      } catch (err) {
        console.log("updateTemplateData", err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },
    async saveTemplateData(
      openNew,
      fromActionButton = false,
      isFromPaymentButton = false,
      paymentInfo = {}
    ) {
      //console.log("Payment info ",paymentInfo);
      if (openNew && this.isFromDialog) {
        this.$store.commit("entitiesData/setSaveAndNew", true, { root: true });
      }
      if (!this.isFromDialog) {
        this.$store.commit("entitiesData/setSaveAndNew", false, { root: true });
      }

      if (this.isUpdatingFormbuilderData && this.entityDataId) {
        this.copyEntityDataForFormbuilder(isFromPaymentButton, paymentInfo);
      } else if (this.isEdit && this.entityDataId && !this.getSaveAndNew) {
        await this.updateTemplateData(isFromPaymentButton, paymentInfo);
        if (this.templateData.isRestrictMultiple) {
          await postAPICall(
            "DELETE",
            `/cache/deleteCacheData/${this.entityDataId}`
          );
        }
      } else {
        this.addNewEntityData(
          openNew,
          fromActionButton,
          isFromPaymentButton,
          paymentInfo
        );
      }
    },
    async copyEntityDataForFormbuilder(
      isFromPaymentButton = false,
      paymentInfo = {}
    ) {
      let form = await this.modifiedFormData();
      let data = {
        template_id: this.templateData._id,
        template_data: form,
        entity_id: this.currentEntity._id,
        existed_data_id: this.entityDataId,
        hard_duplicate: true,
        status: "ARCHIVED",
        add_original_id: true,
      };
      await this.$store.dispatch("templatesData/duplicateEntityData", data);
      if (this.getDuplicateEntityData?._id) {
        // Need to emit data to pay button
        if (isFromPaymentButton) {
          if (paymentInfo && paymentInfo.action) {
            this.$bus.$emit(`initPayButton_${paymentInfo.busKey}`, {
              ...paymentInfo,
              location: window.location.href,
              dataId: this.entityDataId,
              template_id: this.templateData._id,
              entity_id: this.currentEntity._id,
              actionType: "APPROVAL_RECORD",
            });
          }
        } else if (this.currentStep?.has_next) {
          this.$emit("entity-data-created", {
            dataId: this.getDuplicateEntityData?._id,
          });
          this.gotoNextTemplate();
        } else {
          this.$emit("entity-data-created", {
            dataId: this.getDuplicateEntityData?._id,
            moveToNext: true,
          });
        }
      } else {
        this.$message({
          message: "Error while updating data",
          type: "error",
        });
      }
    },
    async updateEntitiesData(
      entityDataId,
      applyFormBuilderRelationships = false
    ) {
      // ** mixin method ** //
      if (this.fromAddWidget || this.fromScheduling) {
        await this.fetchEntityRelationships(this.currentEntity._id);
      }
      let toAssignRelationshipsData = this.prepareRelationshipData(
        this.fieldsData,
        this.getCurrentEntityRelationships,
        entityDataId ? entityDataId : this.entityDataId,
        null,
        this.form
      );
      if (toAssignRelationshipsData.length) {
        await this.$store.dispatch(
          "entityRelationships/assignFieldsEntityRelationshipData",
          toAssignRelationshipsData
        );
      } else if (applyFormBuilderRelationships) {
        this.$emit("update-relationship", entityDataId);
      }
    },
    async prepareFormData() {
      for (let [key, value] of Object.entries(this.filesInfo)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loading = true;
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.filesInfo[key].path = path;
          this.filesInfo[key].new = false;
          this.form[key] = this.filesInfo[key];
        }
      }

      //Apply default values
      // this.form = this.mapDefaultValues(this.fieldsData, this.form, true);
      this.form = await this.mapDataTableRow(this.fieldsData, this.form);
      return this.form;
    },
    checkMappedDataTableRow(form, data) {
      if (form && form[data.key]) {
        form[data.key] = form[data.key].map((e) => {
          Object.keys(e || {}).forEach((key) => {
            if (
              e[key] &&
              e[key + "/name"] &&
              this.getEntityPrimaryLabels &&
              this.getEntityPrimaryLabels[e[key]]
            ) {
              e[key + "/name"] = this.getEntityPrimaryLabels[e[key]];
            }
          });
          return e;
        });
      }
      return form;
    },
    async mapDataTableRow(fields, form) {
      fields.forEach((e) => {
        if (e.inputType == "DATA_TABLE" && e.input_type == "ENTITY_VARIABLE") {
          form = this.checkMappedDataTableRow(form, e);
        }
      });
      return form;
    },
    async uploadFile(file) {
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "template-data-documents");
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      return filesData.data.data;
    },
    async updatedAndUploadDocument(file) {
      let params = {
        data1: {
          file_name: file.raw.name,
          file_type: file.raw.type,
          file_size_in_kb: file.raw.size / 1000,
        },
        data2: file.raw,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentAndGetUrl",
        params
      );
      if (this.getUploadAndUpdateDocument) {
        return this.getUploadAndUpdateDocument;
      } else {
        return "";
      }
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async checkDataTableDuplicateData(fieldsData, form) {
      let result = {
        success: false,
        message: "Error while checking data",
      };
      let duplicateDataTable = fieldsData.find((field) => {
        if (
          field.inputType == "DATA_TABLE" &&
          field.data_table_columns &&
          field.all_duplicate_rules &&
          field.all_duplicate_rules.length
        ) {
          let results = [],
            data = [],
            dateFields = ["DATE", "DATE_TIME"];

          (form[field.key] || []).forEach((dt) => {
            let e = JSON.parse(JSON.stringify(dt));
            field.data_table_columns.forEach((fl) => {
              if (dateFields.indexOf(fl.inputType) != -1 && e && e[fl.key]) {
                e[fl.key] = this.convertDataValue(e[fl.key]);
              }
            });
            data.push(e);
          });
          (field.all_duplicate_rules || []).forEach((el) => {
            results.push(this.checkSingleDuplicate(el, data));
          });
          if (
            field.duplicate_query_type == "$and" &&
            results.every((el) => el)
          ) {
            return true;
          } else if (
            field.duplicate_query_type == "$or" &&
            results.some((el) => el)
          ) {
            return true;
          }
        }
      });
      if (duplicateDataTable?.label) {
        result.message =
          duplicateDataTable.label +
          " has duplicate data. Please add it in one row.";
      } else {
        result.success = true;
      }
      return result;
    },
    convertDataValue(inputDateStr) {
      const inputDate = new Date(inputDateStr);
      const year = inputDate.getFullYear();
      const month = inputDate.getMonth() + 1;
      const day = inputDate.getDate();
      const hours = inputDate.getUTCHours();
      const minutes = inputDate.getUTCMinutes();

      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
      return `${year}-${formattedMonth}-${formattedDay}T${hours}:${minutes}:00.000Z`;
    },
    checkCombinationDuplicates(objArray, propertyNames) {
      const combinations = objArray.map((obj) =>
        propertyNames.map((prop) => obj[prop]).join("-")
      );
      const duplicateCombinations = new Set(
        combinations.filter(
          (combination, index, arr) => arr.indexOf(combination) !== index
        )
      );

      return duplicateCombinations.size > 0;
    },
    checkSingleDuplicate(rules, data) {
      if (data && data.length > 1) {
        let results = [];
        (rules || []).forEach((e) => {
          if (e?.combination_type == "ALL") {
            let counts = this.checkCombinationDuplicates(data, [
              e.selected_field,
            ]);
            results.push(counts);
          } else {
            results.push(false);
          }
        });
        return results.every((el) => el);
      }
      return false;
    },
    async applyRulesAndSaveData(openNew, fromActionButton) {
      this.savingData = true;
      this.resetAlertFields();
      let form = JSON.parse(JSON.stringify(this.form));
      form = this.mapDefaultValues(this.fieldsData, form, true);
      await this.applyRulesOnFields(
        this.fieldsData,
        this.alertRules,
        form,
        true
      );
      this.checkForDuplicateData(openNew, fromActionButton);
    },
    alertMessage(
      message,
      confirmButtonText = "Yes",
      cancelButtonText = "No",
      openNew,
      fromActionButton = false
    ) {
      this.$confirm(message, "Alert", {
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        type: "warning",
      })
        .then(() => {
          this.applyRulesAndSaveData(openNew, fromActionButton);
        })
        .catch(() => {
          this.checkForDuplicateData(openNew, fromActionButton);
          return;
        });
    },
    async checkForDuplicateData(openNew, fromActionButton = false) {
      this.savingData = true;
      if (this.hasRepeatableTemplate) {
        this.saveTemplateData(openNew, fromActionButton);
        return;
      }
      let checkData = await this.checkDataTableDuplicateData(
        this.fieldsData,
        this.form
      );
      if (checkData?.success) {
        if (
          !this.templateData?.all_duplicate_rules ||
          !this.templateData.all_duplicate_rules.length
        ) {
          this.saveTemplateData(openNew, fromActionButton);
          return;
        }
        this.loading = true;
        this.loadingText = "Checking duplicate data...";
        await this.$store.dispatch("templatesData/checkDuplicateData", {
          data: [
            {
              form: this.form,
              template_id: this.templateData._id,
            },
          ],
          entity_id: this.getEntityId,
          parent_entityDataId: this.entityDataId || null,
        });
        if (this.getDuplicateDataCheck) {
          if (!this.getDuplicateDataCheck?.allowed) {
            let name =
              this.currentEntity && this.currentEntity.name
                ? this.currentEntity.name
                : "";
            let message = "With this data already $entity_name created.";
            let match;
            if (this.templateData?.duplicate_message) {
              let parsedMessage = JSON.stringify(
                this.templateData.duplicate_message
              );
              let pattern = /\[\[([^[\]]*)\]\]/g;
              let mentionedSubjectLabels = [];
              while (
                (match = pattern.exec(this.templateData?.duplicate_message)) !==
                null
              ) {
                mentionedSubjectLabels.push(match[1]);
              }
              mentionedSubjectLabels.map((fd) => {
                let [type, label] = fd.split("-").map((f) => f.trim());
                let field = this.fieldsData.find((f) => f.label == label);
                let dataForm =
                  type == "EXISTED"
                    ? this.getDuplicateDataCheck?.existedData?.entityData?.[
                        this.templateData._id
                      ]
                    : this.form;
                let parsedField = ![undefined, null].includes(field)
                  ? this.getFormattedFieldData(field, dataForm)
                  : " - ";
                parsedMessage = parsedMessage.replace(`[[${fd}]]`, parsedField);
              });
              message = parsedMessage;
            }
            message = message.replaceAll("$entity_name", name);
            this.$notify.warning({
              title: "Warning",
              message: message,
            });
            this.loading = false;
            return;
          }
          this.saveTemplateData(openNew, fromActionButton);
        } else {
          this.loading = false;
          this.$message({
            message: "Error while checking data",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: checkData.message || "Error while checking data",
          type: "error",
        });
      }
    },
    async backToEntityInfoList() {
      let tempQuery = {};
      if (this.$route.query?.fromCustom) {
        this.$router.go(-1);
      } else {
        if (this.$route?.query?.filter) {
          tempQuery["filter"] = this.$route.query.filter;
        }
        if (this.$route?.query?.viewType) {
          tempQuery["viewType"] = this.$route.query.viewType;
        }
        if (this.$route?.query?.routeType) {
          tempQuery["routeType"] = this.$route.query.routeType;
        }
        if (this.isApplicationUserSide) {
          this.$router.push({
            path:
              "/ap/entity/" +
              this.currentEntity._id +
              "/" +
              this.$route.query.menu_id,
            query: tempQuery,
          });
        } else {
          let UrlPath = "";
          if (this.getIframeUrlInfo != null) {
            UrlPath = `/if/${this.getIframeUrlInfo._id}/entity/${this.currentEntity._id}`;
          } else {
            UrlPath = `/entity/${this.currentEntity._id}`;
          }
          this.$router.push({
            path: UrlPath,
            query: tempQuery,
          });
        }
      }
    },
    gotoNextTemplate() {
      this.$emit("goto-next");
    },
    refreshThePage(reload) {
      this.$emit("refresh-page", reload);
    },
    existedTemplateDataId() {
      return this.entityData?.templates_data.find(
        (e) => e.template_id == this.templateData._id
      );
    },
    setTemplateData() {
      if (
        this.getCloningData &&
        this.getCloningData[this.currentEntity._id] &&
        this.getCloningData[this.currentEntity._id][this.templateData._id] &&
        this.getCloningData[this.currentEntity._id].keyIndex ==
          this.$route.query.key
      ) {
        this.setFormData(
          this.getCloningData[this.currentEntity._id][this.templateData._id]
        );
        if (!this.currentStep.has_next) {
          this.$store.commit(
            "templatesData/setCloningData",
            {},
            {
              root: true,
            }
          );
        }
      } else if (
        ![undefined, null, ""].includes(this.suggestionEntityForm) ||
        (this.getSuggestionFormData?.[this.templateData._id] &&
          (this.$route.query.fromEntityView || this.$route.query.fromCustom))
      ) {
        this.setFormData(
          this.suggestionEntityForm ||
            this.getSuggestionFormData[this.templateData._id]
        );
      } else {
        let activeTemplateData = this.entityData?.templates_data.find(
          (e) => e.template_id == this.templateData._id
        );
        if (this.isEmailView && this.entityData.entityData) {
          this.setFormData(
            this.entityData.entityData[
              this.entityData.templates_data[0].template_id
            ]
          );
        }
        if (activeTemplateData?.template_data_id?.template_data) {
          this.setFormData(activeTemplateData.template_data_id.template_data);
        } else {
          setTimeout(() => {
            this.showData = true;
          }, 500);
        }
        if (activeTemplateData && activeTemplateData.template_data_id) {
          this.$set(
            this.form,
            "created_by",
            activeTemplateData.template_data_id.created_by
          );
          this.$set(
            this.form,
            "updated_by",
            activeTemplateData.template_data_id.updated_by
          );
          this.$set(
            this.form,
            "created_at",
            activeTemplateData.template_data_id.created_at
          );
          this.$set(
            this.form,
            "updated_at",
            activeTemplateData.template_data_id.updated_at
          );
        } else {
          //console.error("Either activeTemplateData or template_data_id is undefined");
        }
      }
    },
    setFormData(data) {
      this.showData = false;
      if (this.isFromFormBuilder) {
        data =
          data && data[this.templateData?._id]
            ? data[this.templateData._id]
            : data;
      }
      if (this.hasRepeatableTemplate) {
        if (Object.keys(data)) {
          let dt = Object.keys(data).find((e) => typeof data[e] == "object");
          if (data[dt]) {
            let temp = data[dt];
            this.entitiesData = temp.map((e) => {
              if (!e.keyIndex) {
                e.keyIndex = (Math.random() + 1).toString(36).substring(7);
              }

              return e;
            });
          } else {
            this.entitiesData.push(data);
          }
        }
      } else {
        this.existedTemplateDataValues = data;
        this.fieldsData.forEach((el) => {
          let field = JSON.parse(JSON.stringify(el));
          if (field?.key && field.key.includes("#")) {
            field.key = field.key.split("#")[1];
          }
          if (field.inputType === "AUTO_INCREMENT_NUMBER") {
            this.$set(
              this.form,
              field.key + "_prefix",
              data && data[field.key + "_prefix"]
                ? data[field.key + "_prefix"]
                : {}
            );
          }

          if (field.inputType === "PHONE_COUNTRY_CODE") {
            this.$set(
              this.form,
              field.key + "_code",
              data && data[field.key + "_code"] ? data[field.key + "_code"] : {}
            );
          }
          if (field.inputType === "CURRENCY") {
            this.$set(
              this.form,
              field.key + "_currency",
              field.validations.currency
            );
            this.$set(this.form, field.key, data[field.key]);
          }
          if (field.inputType === "PAY_BUTTON") {
            const fieldTypes = [
              "history",
              "info",
              "amount",
              "cust_info",
              "txn_id",
              "gateway",
              "name",
              "email",
              "phoneNumber",
              "phoneNumber_code",
              "paymentType",
              "method",
              "currency",
              "amount_currency",
              "paidAmount",
              "paidAmount_currency",
              "totalPaidAmount",
              "totalPaidAmount_currency",
              "paymentDate",
              "status",
              "paymentSession",
              "amountRefunded",
              "amountRefunded_currency",
              "description",
              "refundDate",
              "cust_info",
            ];
            fieldTypes.forEach((type) => {
              if (data && data[field.key + "_" + type]) {
                this.$set(
                  this.form,
                  field.key + "_" + type,
                  data[field.key + "_" + type]
                );
              }
            });
          }
          if (field.inputType === "NUMBER" && field.input_type === "ZIP_CODE") {
            this.$set(this.form, field.key, data[field.key]);
          }
          if (
            field.inputType === "SINGLE_LINE_TEXT" &&
            field.input_type === "MASKED"
          ) {
            this.$set(
              this.form,
              field.key + "_masked",
              data && data[field.key + "_masked"]
                ? data[field.key + "_masked"]
                : ""
            );
          }
          // if (
          //   field.inputType === "SINGLE_LINE_TEXT" &&
          //   field.input_type === "HYPER_LINK"
          // ) {
          //
          //   this.$set(
          //     this.form,
          //     field.key ,
          //     data && data[field.key ]
          //       ? data[field.key]
          //       : ""
          //   );
          // }
          if (field.input_type === "VIDEO") {
            this.$set(
              this.form,
              field.key + "_video",
              data && data[field.key + "_video"]
            );
          }
          if (field.input_type == "QUESTION" && field.has_other_option) {
            this.$set(
              this.form,
              `${field.key}/otheroption`,
              data[field.key + "/otheroption"]
                ? data[field.key + "/otheroption"]
                : ""
            );
          }
          if (field.input_type == "CHECKBOX_GROUP") {
            this.$set(
              this.form,
              field.key,
              data[field.key] ? data[field.key] : []
            );
          }
          if (field.input_type != "CHECKBOX_GROUP" && data && data[field.key]) {
            this.$set(this.form, field.key, data[field.key]);
          }
          if (
            field.inputType == "FORMULA" &&
            data[field.key] != null &&
            data[field.key] != undefined
          ) {
            this.$set(this.form, field.key, data[field.key]);
          }
          if (
            field?.inputType == "ENTITY" &&
            field?.allow_multiple &&
            data &&
            data[field.key] &&
            typeof data[field.key] == "string"
          ) {
            this.$set(this.form, field.key, [data[field.key]]);
          }
          if (data && data[field.key + "/name"]) {
            this.$set(
              this.form,
              field.key + "/name",
              data[field.key + "/name"]
            );
          }
          if (data && data[field.key + "/actualRecord"]) {
            this.$set(
              this.form,
              field.key + "/actualRecord",
              data[field.key + "/actualRecord"]
            );
          }
          if (data && data[field.key + "/integrationCalvalue"]) {
            this.$set(
              this.form,
              field.key + "/integrationCalvalue",
              data[field.key + "/integrationCalvalue"]
            );
          }
        });
      }
      setTimeout(() => {
        this.showData = true;
      }, 500);
    },
    async setTemplateInfo(templateData) {
      this.loading = true;
      this.interactiveQuestionsMode =
        templateData && templateData.use_interactive_questionare;
      this.interactiveQuestionsModeType =
        templateData && templateData.interactive_questionare_type;
      if (templateData?.sections[0]?.fields) {
        let section =
          templateData &&
          templateData.sections.find((x) => x.type == "DEFAULT");
        let defaultSection = JSON.parse(JSON.stringify(section));
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });
        await this.fetchOtherTemplates(defaultSection.fields);
        defaultSection.fields = this.applyCustomizationOnFields(
          defaultSection.fields,
          this.currentStep?.customization
        );
        if (this.fromAddWidget) {
          defaultSection.fields = defaultSection.fields.filter((field) => {
            let isRelationshipButton = field?.actions?.length
              ? field.actions.findIndex(
                  (action) =>
                    action.action_button_action_type ===
                    "ESTABLISH_RELATIONSHIP"
                )
              : -1;
            return isRelationshipButton === -1;
          });
        }
        if (
          this.entityDataExists &&
          this.entityDataId &&
          this.currentEntity?.editable_fields &&
          this.currentEntity.editable_fields.length
        ) {
          defaultSection.fields = defaultSection.fields.map((e) => {
            let key;
            if (e.key && e.key.includes("#")) {
              key = e.key;
            } else {
              key = templateData._id + "#" + e.key;
            }
            if (this.currentEntity.editable_fields.indexOf(key) !== -1) {
              if (e?.properties) {
                if (
                  !e.properties?.filed_content ||
                  e.properties?.filed_content != "Hide"
                ) {
                  e.properties = {
                    ...e.properties,
                    ...{
                      filed_content: "READ ONLY",
                    },
                  };
                }
              } else {
                e["properties"] = {
                  filed_content: "READ ONLY",
                };
              }
              if (e?.inputType == "DATA_TABLE" && e.data_table_columns) {
                e.data_table_columns = e.data_table_columns.map((el) => {
                  el["entityDataExists"] = true;
                  if (el?.properties) {
                    if (el.properties?.filed_content !== "Hide") {
                      el.properties = {
                        ...el.properties,
                        ...{
                          filed_content: "READ ONLY",
                        },
                      };
                    }
                  } else {
                    el["properties"] = {
                      filed_content: "READ ONLY",
                    };
                  }
                  return el;
                });
              }
            }
            if (this.fieldComponentsMap[e.input_type]) {
              e["components"] = this.fieldComponentsMap[e.input_type];
            }
            return e;
          });
        }
        // const formBuilderWidth = this.$refs.formBuilder.clientWidth;
        let formBuilderWidth = 1200;
        if (this.$refs.formBuilder && this.$refs.formBuilder.clientWidth) {
          formBuilderWidth = this.$refs.formBuilder?.clientWidth;
        }
        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields,
          formBuilderWidth
        );
        this.fieldsObject = {};
        this.fieldsData.map((field) => {
          let key = field.key?.includes("#")
            ? field.key.split("#")[1]
            : field.key;
          this.fieldsObject[key] = field;
        });
        // this.fieldsData = this.setSizeAndPositionForElements(
        //   defaultSection.fields,formBuilderWidth
        // );

        //Someone reordered the methods, leading to not displaying the added repeatable data when we view that.
        await this.checkRepeatableTemplate();
        this.setTemplateData();
        this.setSelectedTableRows();
        let lastElement = defaultSection.fields[this.fieldsData.length - 1];
        this.formHeight = lastElement.y + Number(lastElement.height || 50);
        let newForm = this.mapDefaultValues(
          this.fieldsData,
          JSON.parse(JSON.stringify(this.form)),
          false
        );
        Object.keys(newForm).forEach((key) => {
          this.$set(this.form, key, newForm[key]);
        });
        if (!this.entityDataId) {
          let newForm1 = await this.getAIFieldsResponse(
            this.fieldsData,
            {},
            JSON.parse(JSON.stringify(this.form)),
            "Added"
          );
          Object.keys(newForm1).forEach((key) => {
            this.$set(this.form, key, newForm1[key]);
          });
        }
      }
      this.company_id = templateData?.company;
      if (
        templateData &&
        templateData.sections &&
        templateData.sections[0] &&
        templateData.sections[0].checkBoxGroup
      ) {
        this.singleCompanyTemplateCheckBoxArray =
          templateData.sections[0].checkBoxGroup;
      }
      this.templateStyles =
        templateData && templateData.styles ? templateData.styles : {};
      let templateRules = templateData?.rules ? [...templateData.rules] : [];
      templateRules.forEach((el) => {
        if (el?.show_alert || el?.when == "AFTER_SAVE") {
          this.alertRules.push(el);
        } else {
          this.templateRules.push(el);
        }
      });
      //remove alert fields
      if (this.alertRules.length) {
        this.alertFields = [];
        this.alertRules.forEach((rule) => {
          (rule.actions || []).forEach((el) => {
            if (el.operator_type == "SHOW_IN_POPUP") {
              this.alertFields.push(el.selectField);
            }
          });
        });
        if (this.alertFields.length) {
          this.fieldsData = this.fieldsData.filter(
            (e) =>
              this.alertFields.indexOf(e.key) === -1 &&
              this.alertFields.indexOf(e?.key?.split("#")[1]) === -1
          );
        }
      }
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      this.loading = false;
      if (!this.isTabClickedWithoutSaving) {
        this.initialFormData = this.form;
        this.$emit("formDataUpdated", this.initialFormData);
      }
    },
    calculateRelativeLeft(x, screenWidth, originalScreenWidth) {
      return (screenWidth / originalScreenWidth) * x;
    },

    calculateRelativeTop(y) {
      return y;
    },
    calculateRelativeWidth(width, screenWidth, originalScreenWidth) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
      //   );
      // }
      //let x = (screenWidth / originalScreenWidth) * width
      return (screenWidth / originalScreenWidth) * width;
    },
    setSizeAndPositionForElements(elements, formBuilderWidth) {
      let questionIndex = 1;
      const screenWidth = formBuilderWidth - 50;
      const originalScreenWidth = 1200;
      let entityFields = {};
      elements.forEach((el) => {
        if (el.inputType == "ENTITY") {
          entityFields[el.key] = el;
        }
        if (el.input_type == "DATA_TABLE" || el.input_type == "ENTITY_TABLE") {
          (el.data_table_columns || []).forEach((fl) => {
            if (fl.inputType == "ENTITY") {
              entityFields[fl.key] = fl;
            }
          });
        }
      });
      return elements.map((field) => {
        field = {
          ...field,
        };
        field.x = this.calculateRelativeLeft(
          field.x,
          screenWidth,
          originalScreenWidth
        );
        field.y = this.calculateRelativeTop(
          field.y,
          screenWidth,
          originalScreenWidth
        );

        let width = this.calculateRelativeWidth(
          field.width,
          screenWidth,
          originalScreenWidth
        );

        field.width = width;
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        if (this.getEntityId) {
          field.parent_entityId = this.getEntityId;
          field.parent_entityDataId = this.entityDataId;
        }
        //IF PARENT is not required then we'll make entity variable also non-required
        if (field.input_type == "ENTITY_VARIABLE") {
          let parentField = entityFields[field.global_variable_entity_parent];
          if (
            !parentField?.validations?.required &&
            field?.validations?.required
          ) {
            field.validations.required = false;
          }
        }
        if (
          field.input_type === "DATA_TABLE" ||
          field.input_type == "ENTITY_TABLE"
        ) {
          field.data_table_columns = (field.data_table_columns || []).map(
            (e) => {
              e.parent_entityId = this.getEntityId;
              e.parent_entityDataId = this.entityDataId;
              if (this.templateData?._id) {
                e.template_id = this.templateData._id;
              }
              //IF PARENT is not required then we'll make entity variable also non-required
              if (e.input_type == "ENTITY_VARIABLE") {
                let parentField = entityFields[e.global_variable_entity_parent];
                if (
                  !parentField?.validations?.required &&
                  e?.validations?.required
                ) {
                  e.validations.required = false;
                }
              }
              return e;
            }
          );
        }
        if (this.templateData?._id && !field.template_id) {
          field.template_id = this.templateData._id;
        }
        let key = field.key;
        if (key && key.includes("#")) {
          key = key.split("#")[1];
        }
        return field;
      });
    },
    async checkRepeatableTemplate() {
      if (this.currentStep?.settings?.is_repeatable) {
        this.entityRepeatableTemplateData = {
          senderAlias: this.currentStep?.settings?.sender_title,
          default_display: "TABLE",
          receiverAlias: this.currentStep?.settings?.receiver_title,
          max_records: this.currentStep?.settings?.max_records,
          templateInfo: this.currentStep?.template,
          max_linked_field: this.currentStep?.settings?.max_linked_field,
          min_linked_field: this.currentStep?.settings?.min_linked_field,
          min_records: this.currentStep?.settings?.min_records,
        };
        let fields = [];
        if (this.currentStep?.template?.sections) {
          await this.currentStep?.template.sections.forEach((sec) => {
            fields = [...fields, ...sec.fields];
          });
        }
        await fields.forEach(async (e) => {
          await this.$set(this.form, e.key, "");
        });
        this.hasRepeatableTemplate = true;
      } else if (this.currentEntity && this.currentEntity.templates) {
        this.entityRepeatableTemplateData = this.currentEntity.templates.find(
          (e) => this.templateData && e.template_id == this.templateData._id
        );
        if (
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.is_repeatable
        ) {
          let fields = [];
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo
          ) {
            await this.entityRepeatableTemplateData.templateInfo.sections.forEach(
              (sec) => {
                fields = [...fields, ...sec.fields];
              }
            );
          }
          await fields.forEach(async (e) => {
            await this.$set(this.form, e.key, "");
          });
          this.hasRepeatableTemplate =
            this.entityRepeatableTemplateData.is_repeatable;
          if (this.hasRepeatableTemplate) {
            this.dependentFieldsInfo();
          }
        }
      }
    },
    dependentFieldsInfo() {
      let templateIds = [];
      if (
        this.entityRepeatableTemplateData.min_linked_field &&
        this.entityRepeatableTemplateData.min_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[2],
          is_max: false,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_linked_field &&
        this.entityRepeatableTemplateData.max_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[2],
          is_max: true,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_records &&
        !this.entityRepeatableTemplateData?.max_linked_field
      ) {
        this.max_value = this.entityRepeatableTemplateData.max_records;
      }
      if (
        this.entityRepeatableTemplateData.min_records &&
        !this.entityRepeatableTemplateData?.min_linked_field
      ) {
        this.min_value = this.entityRepeatableTemplateData.min_records;
      }
      if (this.max_value < this.min_value) {
        this.max_value = this.min_value;
      }
      this.fetchDependentData(templateIds);
    },
    async fetchDependentData(templateIds) {
      if (templateIds.length) {
        await this.$store.dispatch("templatesData/fetchRequiredTemplateData", {
          entity_data_ids: [this.entityDataId],
          template_ids: templateIds.flatMap((e) => e.template_id),
        });
        if (
          this.getRequiredTemplatesData &&
          this.getRequiredTemplatesData.length
        ) {
          templateIds.forEach((e) => {
            let seletedTemplateData = this.getRequiredTemplatesData.find(
              (te) => te.template_id == e.template_id
            );
            if (
              seletedTemplateData?.template_data &&
              seletedTemplateData?.template_data[e.field]
            ) {
              if (e.is_max) {
                this.max_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              } else {
                this.min_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              }
            }
            if (this.max_value < this.min_value) {
              this.max_value = this.min_value;
            }
          });
        }
      }
    },
    async saveTemplateDataForPayment(data, isFromPayButton = false) {
      if (this.entityDataId && data.templateId === this.templateData._id) {
        this.updateTemplateDataForPayment(isFromPayButton);
      }
      //else {
      // this.addNewEntityDataForPayment();
      //}
    },
    async updateTemplateDataForPayment(isFromPayButton = false) {
      try {
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          const promises = await this.entitiesData.map(async (e) => {
            //Update parent entity data by entity variable - mixin method
            if (!this.isApprovalForm) {
              await this.updateParentDataByEntityVariable(this.fieldsData, e);
            }
            for (let [key, value] of Object.entries(e)) {
              if (
                value &&
                typeof value == "object" &&
                value.type == "DOCUMENT" &&
                value.new == true &&
                value.file
              ) {
                this.loadingText = "Uploading files...";
                let path = await this.updatedAndUploadDocument(value.file);
                this.$set(e[key], "path", path);
                this.$set(e[key], "new", false);
                // this.form[key].path = path;
                // this.form[key].new = false;
              }
            }
            if (e.isNewData) {
              delete e.isNewData;
            }

            return e;
          });
          form[key] = await Promise.all(promises);
        } else {
          let incrementFields = this.fieldsData.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }
          //Update parent entity data by entity variable - mixin method
          if (!this.isApprovalForm) {
            await this.updateParentDataByEntityVariable(
              this.fieldsData,
              this.form
            );
          }
          form = await this.prepareFormData();
          if (
            this.form &&
            this.form.first_name &&
            this.form.last_name &&
            this.form.first_name.length &&
            this.form.last_name.length &&
            !this.form.name
          ) {
            this.form.name = this.form.first_name + " " + this.form.last_name;
          }
        }
        let params = {
          template_data: form,
          entity_data_id: this.entityDataId,
          template_id: this.templateData._id,
          entity_id: this.getEntityId,
        };
        this.loading = true;
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
        this.loading = false;
        if (this.getTemplatesDataUpdateStatus) {
          if (isFromPayButton) {
            // Emit the entity update status to Payment button
          }
          this.$notify.success({
            title: "Success",
            message: "Data updated successfully",
          });
          //this.$bus.$emit("fetch-entity-data");
          await this.updateEntitiesData();
          // this.gotoNextTemplate();
        } else {
          let msg = "Error while updating";
          if (this.getUserTemplateDataUpdateErrors?.message) {
            msg = this.getUserTemplateDataUpdateErrors.message;
          }
          this.$notify.error({
            title: "Error",
            message: msg,
          });
        }
      } catch (err) {
        console.log(" updateTemplateDataForPayment", err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },
    async addNewEntityDataForPayment() {
      try {
        this.loading = true;
        this.loadingText = "Adding new data...";
        let form = {};
        if (this.hasRepeatableTemplate) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          form[key] = this.entitiesData.map((e) => {
            if (e.isNewData) {
              delete e.isNewData;
            }
            return e;
          });
        } else {
          // form = this.form;
          let incrementFields = this.fieldsData.filter(
            (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
          );

          if (incrementFields && incrementFields.length) {
            await incrementFields.map(async (field) => {
              this.form[`${field.key}_info`] = {
                number: field.auto_increment_starting_number,
                prefix: field.auto_increment_prefix,
              };
            });
          }

          form = await this.prepareFormData();
          if (
            form &&
            form.first_name &&
            form.last_name &&
            form.first_name.length &&
            form.last_name.length &&
            !form.name
          ) {
            form.name = form.first_name + " " + form.last_name;
          }
        }
        let data = {
          template_id: this.templateData._id,
          template_data: form,
          user_id: this.getNewFormbuilderData,
          entity_id: this.getEntityId,
          template_completion_status:
            this.getRquiredFieldsCount === this.getTotalFieldsCount,
          entity_data_id: this.entityDataId ? this.entityDataId : null,
        };
        if (this.existedTemplateDataId) {
          data.template_data_id = this.existedTemplateDataId;
        }
        await this.$store.dispatch(
          "templatesData/createEntityDataByTemplateData",
          data
        );

        if (this.getEntityDataCreateByTemplateStatus) {
          this.$notify.success({
            title: "Success",
            message: "Data saved successfully",
          });

          if (this.getNewEntityData?._id) {
            this.checkAndSetRelationship(this.getNewEntityData?._id);
            // we have to do entity stuff
            await this.updateEntitiesData();
          }
        } else {
          if (
            this.getNewEntityCreateError &&
            this.getNewEntityCreateError.message
          ) {
            this.$notify.error({
              title: "Error",
              message: this.getNewEntityCreateError.message,
            });
          } else {
            this.$notify.error({
              title: "Error",
              message: "Error at saving data",
            });
          }
          this.loading = false;
        }
      } catch (err) {
        console.log("addNewEntityDataForPayment", err);
      }
    },
    checkButtonVisible(button) {
      if (this.hideActions) {
        return false;
      }
      if (
        this.customization &&
        this.customization.buttons &&
        this.customization.buttons.length
      ) {
        if (button == "Save & New") {
          return this.customization.buttons[2].visibility;
        } else if (button == "Duplicate") {
          return this.customization.buttons[5].visibility;
        }
      }
      return true;
    },
    checkAddPermission() {
      let appAddPermission = this.checkPerimission("ADD");
      let applicationUserSidePermission = true;
      if (this.isApplicationUserSide) {
        applicationUserSidePermission =
          this.$route.query.type == "s7aLF3NnAQg=" ||
          this.type == "s7aLF3NnAQg="
            ? true
            : false;
      }
      return appAddPermission && applicationUserSidePermission;
    },
    checkEditPermission() {
      let appEditPermission = this.checkPerimission("EDIT");
      let applicationUserSidePermission = true;
      if (this.isApplicationUserSide) {
        applicationUserSidePermission =
          this.$route.query.type == "AUfYWb7SC6U=" ||
          this.type == "AUfYWb7SC6U="
            ? true
            : false;
      }
      return appEditPermission && applicationUserSidePermission;
    },
    changeTemplate() {
      this.$emit("goto-next");
      if (!this.isSplitView) {
        this.$emit("goToNextTab");
      }
    },
    setSelectedTableRows() {
      this.fieldsData.map((e) => {
        if (e.input_type == "DATA_TABLE" || e.inputType == "DATA_TABLE") {
          this.allSelectedDataTableRows[`${e.template_id}#${e.key}`] = [];
        }
      });
    },
    loadCurrentComponent(data) {
      if (data.value) {
        this.loadingText = data.loadingText;
        this.loading = true;
      } else {
        this.loading = false;
      }
    },
    handleFileUploadData(data) {
      let suggestedDataFields = ["ENTITY", "DATA_TABLE", "ENTITY_TABLE"];
      Object.keys(data).map((e) => {
        let field = this.fieldsObject[e];
        if (field && !suggestedDataFields.includes(field?.input_type)) {
          this.$set(this.form, e, data[e]);
        }
      });
      this.suggestedData = {
        ...data,
      };
    },
    async getVariblesDataByIntegration(
      integrationField,
      eventDetails,
      events,
      application
    ) {
      try {
        let connection = "";
        let asset;
        const variableEvent = events.find(
          (event) => event.asset === eventDetails.settings.eachRecordByEvent
        );
        asset = variableEvent.asset;
        let requiredData = {};
        const requiredKeys = variableEvent.settings.requiredFields.map(
          (field) => field.key
        );
        const requiredEvents = events.filter((event) =>
          requiredKeys.includes(event.asset)
        );
        const addUniqueValue = (requiredData, assetName, value) => {
          if (!requiredData[assetName]) {
            requiredData[assetName] = value;
          }
        };
        requiredEvents.forEach((event) => {
          this.fieldsData.forEach((field) => {
            if (event._id === field.integration_settings.selectedEvent) {
              const value = this.form[field.key];
              if (value !== undefined && value !== null && value !== "") {
                const assetName = event.asset;
                addUniqueValue(requiredData, assetName, value);
              }
            }
          });
        });
        Object.keys(requiredData).forEach((field) => {
          if (Array.isArray(requiredData[field])) {
            const uniqueValues = [...new Set(requiredData[field])];
            if (uniqueValues.length === 1) {
              requiredData[field] = uniqueValues[0];
            } else {
              requiredData[field] = uniqueValues;
            }
          } else {
            requiredData[field] = String(requiredData[field]);
          }
        });
        if (integrationField.integration_settings.selectedIntegration) {
          connection =
            integrationField.integration_settings.selectedIntegration;
        } else {
          const templateDependencies =
            integrationField.integration_settings.templateDependencies || [];
          templateDependencies.forEach((dep) => {
            const fieldData = this.fieldsData.find(
              (field) => field.key === dep
            );
            if (
              fieldData &&
              fieldData.integration_settings.selectedIntegration
            ) {
              connection = fieldData.integration_settings.selectedIntegration;
            }
          });
        }
        const requestBody = {
          selectedApplication: application.key,
          selectedEvent: asset,
          selectedIntegration: connection,
          requiredFields: requiredData,
        };
        let variablesData = await this.fetchEventData(requestBody);
        return variablesData.data;
      } catch (err) {
        if (err.response) {
          throw new Error(
            `API Error: ${err.response.data.message || "Unknown error"}`
          );
        } else if (err.request) {
          throw new Error("No response received from the API.");
        } else {
          throw new Error(`Error: ${err.message}`);
        }
      }
    },
    async fetchEventData(requestBody) {
      try {
        const response = await postAPICall(
          "POST",
          `integrations/service/get-event-data`,
          requestBody
        );
        const data = response.data;
        return data;
      } catch (error) {
        return error;
      }
    },
    handleFormChange() {
      console.log(".");
    },
    handleOnClickAction(selectedMaskedFields) {
      if (!this.maskedFields || !(this.maskedFields instanceof Set)) {
        this.maskedFields = new Set();
      }
      if (!this.originalValues) {
        this.originalValues = {};
      }
      selectedMaskedFields.forEach((fieldKey) => {
        if (
          this.form &&
          Object.prototype.hasOwnProperty.call(this.form, fieldKey)
        ) {
          if (this.maskedFields.has(fieldKey)) {
            this.maskedFields.delete(fieldKey);
            this.form[fieldKey] = this.originalValues[fieldKey];
          } else {
            this.originalValues[fieldKey] = this.form[fieldKey];
            this.form[fieldKey] = "*".repeat(
              String(this.form[fieldKey]).length
            );
            this.maskedFields.add(fieldKey);
          }
        }
      });
    },
    SaveScheduleEventData(data) {
      if (data.scheduleEvent) {
        this.isFromScheduleAction = true;
        this.saveTemplate();
      }
    },
  },
  watch: {
    saveTemp: {
      handler() {
        if (this.saveTemp) {
          this.isSaveAsDraft
            ? this.addNewEntityData(this.openNew)
            : this.saveTemplate(this.openNew);
        }
      },
      deep: true,
    },
    form: {
      handler() {
          this.$emit(
            "getDataFromTemplatesPreview",
            JSON.parse(JSON.stringify(this.form))
          );
      },
      deep: true,
      immediate: true,
    },
    isExecute: {
      async handler() {
        if (this.isExecute) {
          await this.mountedDataMethod();
        }
      },
      deep: true,
      immediate: true,
    },
    refreshKey: {
      async handler() {
        if (this.refreshKey) {
          await this.mountedDataMethod();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Auto-adjust based on screen size */
  border: 1px solid rgb(255, 0, 195);
  // max-width: 200px;
  // gap: 16px; /* Space between grid items */
  // margin: 20px;
}

.grid-container > * {
  background: #f9f9f9; /* Optional background */
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ddd; /* Optional border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional box shadow */
}
.preview-template-view {
  .header-action-block {
    margin-right: 10px;
  }

  .template-body-section {
    .el-scrollbar {
      border: 1px solid #e4e7ec;
      overflow: scroll;
      position: relative;
    }

    width: 99%;
    max-height: 84vh;
    overflow-y: auto;
    scrollbar-width: thin;
    // @media (max-width: 766.98px) {
    //   width: 70%;
    // }
  }
}

.formbuilder-as-a-parent {
  .header-action-block {
    margin-right: 10px;
  }

  .template-body-section {
    .el-scrollbar {
      border: 1px solid #e4e7ec;
      overflow: scroll;
      position: relative;
    }

    width: 99%;
    // @media (max-width: 766.98px) {
    //   width: 70%;
    // }
  }
}

.template-body-section {
  .form-builder {
    position: relative;
    .form-fields-holder {
      @media (max-width: 991.98px) {
        & > div {
          position: relative !important;
          left: 10px !important;
          top: 0px !important;
          margin-top: 30px;
          width: calc(100% - 20px) !important;

          input {
            width: 100% !important;
          }

          .heading {
            margin-top: 40px !important;
          }

          p {
            width: 100% !important;
          }
        }

        /* Corrected selector for the first div */
        & > div:first-of-type {
          margin-top: 80px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.sub-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em !important;
  border: 1px solid #285ed3;
  background: transparent;
  color: #285ed3 !important;
  margin-right: 10px !important;

  &:hover {
    color: white !important;
    background: #285ed3;
    border: 1px solid #285ed3 !important;
  }
}

.edit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c !important;

  &:hover {
    color: white !important;
    background: #f68c8c !important;
    border: 1px solid #f68c8c !important;
  }
}

.preview-template-view {
  margin: 0em 0em;
  background-color: #ffffff;
  box-shadow: 0 0px 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border: 2px solid #e6e6e6;
  border-radius: 20px;

  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .preview-form-header {
    margin-top: 1em;
    margin-left: 10px;
    z-index: 100;

    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }

    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }

  .header-action-block {
    margin-right: 10px !important;

    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }

  .footer-action-block {
    //position: relative !important;
    margin-bottom: 3em;

    .el-button {
      margin-top: -39px;
      display: block;
      padding: 0.7em 1em;
      margin-right: 20px;
      min-width: 20px;
      font-size: 1em;
    }

    .required-fields-info {
      top: 10px;
      font-size: 14px;

      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }

  .sty {
    margin-top: 500px;
  }

  .form-builder {
    position: relative;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    width: 100% !important;

    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
}

.formbuilder-as-a-parent {
  margin: 0em 0em;

  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .preview-form-header {
    margin-top: 1em;
    margin-left: 10px;
    z-index: 100;

    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }

    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }

  .header-action-block {
    margin-right: 10px !important;

    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }

  .footer-action-block {
    //position: relative !important;
    margin-bottom: 3em;

    .el-button {
      margin-top: -39px;
      display: block;
      padding: 0.7em 1em;
      margin-right: 20px;
      min-width: 20px;
      font-size: 1em;
    }

    .required-fields-info {
      top: 10px;
      font-size: 14px;

      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }

  .sty {
    margin-top: 500px;
  }

  .form-builder {
    position: relative;
    max-height: 80vh;

    @media (max-width: 991.98px) {
      width: 100%;
      max-height: 74vh;
    }

    width: 100% !important;

    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      scrollbar-width: thin;

      .each-element img {
        max-width: 100%;
      }
    }

    .form-fields-holder::-webkit-scrollbar {
      width: 12px;
    }
  }
}
.ai-icon {
  color: var(--primary-color);
}
.ai-icon-content {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
  color: var(--primary-color);
}

.ai-text {
  margin-left: 5px;
  color: var(--primary-color);
  font-weight: 500;
}
</style>
<style scoped>
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
</style>
<style>
.scaling-button {
  transform: scale(1);
  transition: transform 0.3s;
}

.scaling-button:hover {
  transform: scale(1.05);
}

.scrollable-element {
  /* Add any other styling you want for the container */
  /* For example, set a fixed height or width for the container */
  /* Set the scrollbar to be transparent */
  scrollbar-width: thin;
  /* Firefox */
  /* Firefox */
  scrollbar-track-color: transparent;
  /* Chrome, Edge, Safari */
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100vw;
}

/* For Webkit (Chrome, Edge, Safari) */
.scrollable-element::-webkit-scrollbar {
  width: 6px;
  /* Adjust the width as needed */
}

.scrollable-element::-webkit-scrollbar-thumb {
  background-color: transparent;
  /* Make the thumb transparent */
}

.scrollable-element::-webkit-scrollbar-track {
  background-color: transparent;
  /* Make the track transparent */
}

.el-scrollbar__thumb {
  position: relative;
  display: none;
  width: 0;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(144, 147, 153, 0.3);
  -webkit-transition: 0.3s background-color;
  transition: 0.3s background-color;
}

.approver-text {
  color: #3366cc;
}

.list-style {
  margin-top: 10px;
}

.preview-template-view .el-scrollbar__wrap {
  overflow: auto;
  height: 100%;
}

.preview-template-view .el-scrollbar__wrap--hidden-default {
  scrollbar-width: thin;
}

.formbuilder-as-a-parent .el-scrollbar__wrap {
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
}

.formbuilder-as-a-parent .el-scrollbar__wrap--hidden-default {
  scrollbar-width: thin;
}
.el-button--primary {
  border: none !important;
  border-radius: 5px !important;
}
.bottom-bar {
  text-align: right;
}
</style>
